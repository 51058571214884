import { Box, Grid, InputBase, Typography } from "@mui/material";
import { Styles } from "../styles/style";
import { ComponentButtonIcon } from "../components/ButtonIcon";
import { Search } from "@mui/icons-material";
import { InputSelect } from "../components/Select";
import RapportTable from "../components/RapportTable";
import { rapportTable } from "../actions/data";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { ShadowBox } from "../components/BoxShadow";
import { CustomizePagination } from "../components/Pagination";
import { CustomDatePicker } from "../components/CustomDatePicker";
import { useEffect, useRef, useState } from "react";
import visite from "../actions/visite";
import visitor from "../actions/visitors";
import enterprise from "../actions/enterprise";
import category from "../actions/category";
import visited from "../actions/visitedPersonne";
import { paginationFilter } from "../actions/pagination";
import { selectItems } from "../actions/data";
import { visitorSearch } from "../actions/visitorSearch";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ShowColumn } from "../components/showColumn";
import { HoursFormat, dateFormat } from "../actions/dateFormated";
import useShowColumn from "../customHook/useShowColumn";

export const Rapport = ({ 
    openFullMenu
})=> {
    const tableRef = useRef(null);
    const [isOnload, setIsOnload] = useState(true);
    const [isOnSearch, setIsOnSearch] = useState("");
    const [dataBody, setDataBody] = useState([]);
    const [pageNumbers, setPageNumbers] = useState(null);
    const [linesNumbers, setLinesNumbers] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    const [createdAt, setCreatedAt] = useState(null);
    const [backedAt, setBackedAt] = useState(null);
    const [visitorItems, setVisitorItems] = useState("");
    const [enterpriseItems, setEnterpriseItems] = useState("");
    const [categoryItems, setCategoryItems] = useState("");
    const [visitedItems, setVisitedItems] = useState("");
    const [selectedItems, setSelectedItems] = useState(
        [
            ["Tous"],["Tous"],["Tous"],["Tous"]
        ]
    );
    const isDefault = (
        isOnSearch ||
        createdAt ||
        backedAt ||
        visitorItems ||
        enterpriseItems ||
        categoryItems ||
        visitedItems
    )
    const [orderBy, setOrderBy] = useState(()=>{
        return {
            asc: 1,
            count: -1
        }
    });

    const [HeaderTable, setHeaderTable] = useState(()=>rapportTable);

    const {
        open,
        anchorEl,
        menu,
        handleOpenRow,
        handleCloseRow,
        handleShowColumn
    } = useShowColumn(HeaderTable, setHeaderTable);

    const isFilter = (
        isOnSearch || 
        createdAt ||
        backedAt ||
        (visitorItems && visitorItems !== "Tous") ||
        (enterpriseItems && enterpriseItems !== "Tous") ||
        (categoryItems && categoryItems !== "Tous") ||
        (visitedItems && visitedItems !== "Tous")
    )

    const handleCreatedAtChange = (date)=> {
        try {
            setCreatedAt(date.$d.toISOString());
        } catch (err) {
            setCreatedAt(null);
        }
    }

    const handleBackedAtChange = (date)=> {
        try {
            setBackedAt(date.$d.toISOString());
        } catch (err) {
            setBackedAt(null);
        }
    }

    const handleVisitorItemsChange = (event)=> {
        setVisitorItems(event.target.value);
    }

    const handleEnterpriseItemsChange = (event)=> {
        setEnterpriseItems(event.target.value);
    }

    const handleCategoryItemsChange = (event)=> {
        setCategoryItems(event.target.value);
    }

    const handleVisitedChange = (event)=> {
        setVisitedItems(event.target.value);
    }

    const handlePagesNumbers = ()=> {
        const pages = isFilter ?
            Math.floor((dataFilter.length - 1) / checkPagination)
        :
            Math.floor((dataBody.length - 1) / checkPagination);

        if (!isDefault) return setPageNumbers(-1);

        setPageNumbers(pages);
    }

    const handlePaginationActive = (activePage)=> {
        setActivePage(activePage)
    }
    
    const handlePaginationChecked = (checkPagination)=> {
        setCheckPagination(checkPagination)
    }

    useEffect(()=> {
        const callItemsData = async ()=> {
            const dataEnterprise = await enterprise.getEnterprise();
            const dataCategory = await category.getCategory();
            const dataVisited = await visited.getVisitedPersonne();
            const dataVisitor = await visitor.getVisitor();
            const _Enterprises = dataEnterprise.map(data => data?.denomination || []);
            const _Categories = dataCategory.map(data => data?.libelle || []);
            const _Visiteds = dataVisited.map(data => data?.nom + " " + (data?.prenom || "") || []);
            const _Visitors = dataVisitor.map(data => data?.nom + " " + (data?.prenom || "") || []);
            setSelectedItems([[..._Visitors, "Tous"], [..._Enterprises, "Tous"], 
            [..._Categories, "Tous"], [..._Visiteds, "Tous"]]);
        }
        callItemsData()
    }, [dataBody])

    useEffect(()=> {
        const results = paginationFilter(dataBody, activePage, checkPagination);
        const dataFiltered = visitorSearch(
            results, 
            isOnSearch, 
            null,
            createdAt,
            backedAt,
            visitorItems,
            enterpriseItems,
            categoryItems,
            visitedItems
        );
        setDataFilter(dataFiltered);
        setLinesNumbers(isDefault 
            ? 
            dataFiltered.length
        : 0);
    }, [    
        dataBody, 
        activePage, 
        checkPagination, 
        isOnSearch, 
        createdAt,
        backedAt,
        visitorItems,
        enterpriseItems,
        categoryItems,
        visitedItems,
        isDefault
    ]);

    const handleHeaderOrder = (index)=> {

        const nextState = {
            ...orderBy,
            count: index,
            asc: orderBy.count === index 
                ? orderBy.asc * -1
                : orderBy.asc
        }

        setOrderBy(nextState);

        const dataOrdered = [...dataFilter];

        switch(index) {
            case 0:
                dataOrdered.sort((a, b) => {
                    if (a?.numvisiteur < b?.numvisiteur) {
                        return - nextState.asc;
                    }
                    if (a?.numvisiteur > b?.numvisiteur) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 1: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.nom < b?.visiteur?.nom) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.nom > b?.visiteur?.nom) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 2: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.societe?.denomination < b?.visiteur?.societe?.denomination) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.societe?.denomination > b?.visiteur?.societe?.denomination) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 3: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.categorieVisiteur?.libelle < b?.visiteur?.categorieVisiteur?.libelle) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.categorieVisiteur?.libelle > b?.visiteur?.categorieVisiteur?.libelle) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 4: 
                dataOrdered.sort((a, b) => {
                    if (a?.personnelVisite?.nom < b?.personnelVisite?.nom) {
                        return - nextState.asc;
                    }
                    if (a?.personnelVisite?.nom > b?.personnelVisite?.nom) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 5: 
                dataOrdered.sort((a, b) => {
                    if (dateFormat(a?.dateEntre) < dateFormat(b?.dateEntre)) {
                        return - nextState.asc;
                    }
                    if (dateFormat(a?.dateEntre) > dateFormat(b?.dateEntre)) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 6: 
                dataOrdered.sort((a, b) => {
                    if (HoursFormat(a?.dateEntre) < HoursFormat(b?.dateEntre)) {
                        return - nextState.asc;
                    }
                    if (HoursFormat(a?.dateEntre) > HoursFormat(b?.dateEntre)) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 7: 
                dataOrdered.sort((a, b) => {
                    if ((dateFormat(a?.dateSortie) || "" ) < (dateFormat(b?.dateSortie) || "")) {
                        return - nextState.asc;
                    }
                    if ((dateFormat(a?.dateSortie) || "" ) > (dateFormat(b?.dateSortie) || "")) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 8: 
                dataOrdered.sort((a, b) => {
                    if ((HoursFormat(a?.dateSortie) || "") < (HoursFormat(b?.dateSortie) || "")) {
                        return - nextState.asc;
                    }
                    if ((HoursFormat(a?.dateSortie) || "") > (HoursFormat(b?.dateSortie) || "")) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            default:
                return setDataFilter(dataFilter);
        }
    }

    useEffect(()=> {
        setIsOnload(true);
        const getVisiteList = async ()=> {
            const data = await visite.getVisite();
            setDataBody(data);
            setIsOnload(false);
        }
        getVisiteList();
    }, [])
    return(
        <>
            <ShadowBox>
                <Grid container rowSpacing={1} justifyContent={{
                        xs: 'center',
                        md: 'space-between'
                    }} alignItems="center"
                >
                    <Grid item  sx={Styles.fontTitle} style={{
                        color: Styles.color.blue,
                        margin: "15px 0 12px 0"
                    }}>
                        Rapport 
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}  justifyContent={{
                            xs: 'center',
                            md: 'flex-end'
                        }} alignItems="center">
                            <Grid item>
                                <DownloadTableExcel
                                    filename="rapport"
                                    sheet=".xlsx"
                                    currentTableRef={tableRef.current}
                                >
                                    <ComponentButtonIcon backgroundColor={"#8D9496"}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: "0 15px"
                                        }}>
                                            <img src={"/assets/icons/excel.png"} alt="Excel" style={{
                                                display: 'block',
                                                alignItems: 'center',
                                                marginRight: 10
                                            }} />
                                            <Typography sx={Styles.fontTitle}>
                                                Export
                                            </Typography>
                                        </Box>
                                    </ComponentButtonIcon>
                                </DownloadTableExcel>
                            </Grid>
                            <Grid item xs>
                                <Box style={ Styles.boxModel } sx={{
                                    width: {
                                        xs: '100%',
                                        md: '395px'
                                    },
                                    height: '2.813rem !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `1.5px solid ${Styles.color.grey}`,
                                }}>
                                    <Search sx={{
                                        marginLeft: 1,
                                        color: Styles.color.grey
                                    }}/>
                                    <InputBase placeholder="Recherche..." 
                                        sx={{
                                            fontFamily: "Montserrat",
                                            border: `1px solid transparent`, 
                                            width: "100%",
                                            padding: "0 10px",
                                            color: Styles.color.grey
                                        }}
                                        value={isOnSearch}
                                        onChange={e => setIsOnSearch(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <hr style={{ color: Styles.color.blue, margin: "20px auto" }}/>
                <Grid container spacing={1} justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={3} md={1}>
                        <ShowColumn
                            open={open}
                            anchorEl={anchorEl}
                            handleOpenRow={handleOpenRow}
                            handleCloseRow={handleCloseRow}
                            handleShowColumn={handleShowColumn}
                            menu={menu}
                        />
                    </Grid>
                    <Grid item xs sx={{
                        marginTop: 1.5
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                md: 'flex-end'
                            },
                            flexWrap: "wrap"
                        }}>
                            <Box 
                                sx={{
                                    marginRight: 1,
                                    marginBottom: 1
                                }}
                            >
                                <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"
                                >
                                    <CustomDatePicker onChange={handleCreatedAtChange}>Début</CustomDatePicker>
                                </LocalizationProvider>
                            </Box> 
                            <Box 
                                sx={{
                                    marginRight: 1,
                                    marginBottom: 1
                                }}
                            >
                                <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"
                                >
                                    <CustomDatePicker onChange={handleBackedAtChange}>Fin</CustomDatePicker>
                                </LocalizationProvider>
                            </Box> 
                            {selectItems.map((option, index) => {
                                return(
                                        <Box 
                                            key={index}
                                            sx={{
                                                marginRight: 1,
                                                marginBottom: 1
                                            }}
                                        >   
                                            <InputSelect 
                                                options={selectedItems.at(index)} 
                                                defaultOption={option}
                                                handleChangeOption={
                                                    index === 0 ?
                                                        handleVisitorItemsChange  
                                                    : index === 1 ?
                                                        handleEnterpriseItemsChange
                                                    : index === 2 ?
                                                        handleCategoryItemsChange
                                                    : 
                                                        handleVisitedChange
                                                }
                                            />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justifyContent="center" sx={{
                    marginTop: 2
                }}>
                    <Grid item xs>
                        <RapportTable 
                            tableRef={tableRef}
                            openFullMenu={openFullMenu} 
                            dataHeader={HeaderTable} 
                            isOnload={isOnload} 
                            dataBody={!isDefault ? [] : dataFilter}
                            menu={menu}
                            handleHeaderOrder={handleHeaderOrder}
                        />
                    </Grid> 
                </Grid>
            </ShadowBox>
            <Box sx={{
                padding: 5,
                paddingTop: 0
            }}>
                <CustomizePagination 
                    linesNumbers={linesNumbers}
                    pageNumbers={pageNumbers}
                    handlePagesNumbers={handlePagesNumbers}
                    handlePaginationChecked={handlePaginationChecked}
                    handlePaginationActive={handlePaginationActive}
                />
            </Box>
        </>
    )
}