import { 
    Box, 
    Grid, 
    Typography, 
    TextField, 
    Button, 
    CircularProgress,
    OutlinedInput, 
    InputAdornment, 
    IconButton, 
    Checkbox
} from '@mui/material';
import { Styles } from '../styles/style';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightAlt, Visibility, VisibilityOff } from '@mui/icons-material';
import users from '../actions/users';
import { store } from "../actions/store";

export const SignInComponent = ({ setFallBackPath }) => {
    const navigate = useNavigate();
    const isAuth = store.tokenObject.authValue.isSignIn;
    const [error, setError] = useState(null);
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        username: "",
        password: ""
    });
    const [checked, setChecked] = useState(false);

    const handleHiddenPassword = ()=> {
        setPasswordHidden(prev => !prev);
    }

    const handleUserChange = (e)=> {
        setUser({...user, [e.target.name]: e.target.value });
    }
    
    const signIn = useSignIn();

    const handleSubmit = async (e)=> {
        e.preventDefault();

        try {     
            setLoading(true);
            
            const response = await users.login(user.username, user.password);

            if (!response?.data?.token) return;

            
            if(signIn({
                auth: {
                    token: response.data.token,
                    type: 'Bearer'
                },
                refresh: '',
                userState: {
                    email: user.username
                }
            })){
                navigate('/');
                window.location.reload();
            } else {
                setError('Une erreur a servenu');
            }

        } catch (err) {
            setError("Authentification invalide");
        } finally {
            setLoading(false);
        }

    }

    useEffect(()=> {
        if(isAuth) navigate('/');
    }, [isAuth, navigate])

    return (
        <Box sx={{
            background: `url(${'/assets/images/fond.png'})`,
            width: "100%",
            height: "100%"
        }}>
            <Box sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: {
                    xs: 200,
                    sm: 300,
                    md: 350,
                    lg: 380,
                    xl: 400
                },
                height: 420,
                margin: "auto",
                boxShadow: "0px 4px 20px 0px #0000001A",
                backgroundColor: "#fff",
                borderRadius: 1.5,
                padding: "1.5rem"
            }}>
                <img 
                    src={'/assets/icons-nav/Vimasys.png'} 
                    alt="visitors" 
                    width="70%"
                    style={{
                        display: "block",
                        margin: "1rem auto"
                    }}
                />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} sx={{
                        marginTop: error ? 0 : 1
                    }}>
                        <Grid item xs>
                            {
                                error &&
                                <Grid container sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "0.8rem",
                                    color: Styles.color.red,
                                    justifyContent: "center",
                                }}>
                                    { error }
                                </Grid>
                            }
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Email
                                </Typography>
                                <TextField placeholder="email"  sx={{ width: '100%' }}
                                    InputProps={{
                                        sx: Styles.propsTextField,
                                        type: "email"
                                    }}
                                    name="username"
                                    onChange={handleUserChange}
                                />
                                <Typography style={ Styles.typography }>
                                    Mot de passe
                                </Typography>
                                <OutlinedInput
                                    placeholder="mot de passe"
                                    type={passwordHidden ? "password" : "text"}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleHiddenPassword}
                                            edge="end"
                                        >
                                        {passwordHidden ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    sx={{ width: '100%' }}
                                    style={ Styles.propsTextField }
                                    name="password"
                                    onChange={handleUserChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid 
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <Grid 
                            item 
                            xs={12} 
                            md={6}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1
                            }}>
                                <Checkbox 
                                    value={checked}
                                    onChange={e => setChecked(e.target.checked)}
                                    sx={{
                                        padding: 0
                                    }}
                                /> 
                                <Typography 
                                    sx={Styles.inputDefaultTypo}
                                    style={{
                                        fontSize: 14
                                    }}
                                >
                                    Se souvenir de moi
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid 
                            item
                            xs={12} 
                            md={6}
                            textAlign={{
                                xs: "start",
                                md: "end"
                            }}
                        >
                            <Link to="/forgot" style={{
                                    fontSize: 14,
                                    color: Styles.color.darkGrey
                                }}
                                onClick={() => setFallBackPath("/forgot")}
                            >
                                Mot de passe oublié?
                            </Link>
                        </Grid>
                    </Grid>
                    <Button sx={{
                        backgroundColor: Styles.color.blue,
                        border: `1px solid ${Styles.color.blue}`,
                        width: "100%",
                        marginTop: 3,
                        textTransform: "capitalize",
                        color: "white",
                        "&:hover": {
                            backgroundColor: Styles.color.blue,
                            color: "#ddd"
                        }
                    }} type='submit'>
                        {
                            loading ?
                            <CircularProgress color='secondary' size={20} sx={{
                                marginRight: 1.5
                            }}/> :
                            <ArrowRightAlt sx={{
                                marginRight: 1
                            }}/>
                        }
                        Se connecter
                    </Button>
                </form>
            </Box>
        </Box>
    );
}