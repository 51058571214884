import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { Styles } from '../styles/style';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LoadingData, NullData } from './Loading';
import uuid from 'react-uuid';
import { dateFormat, HoursFormat } from '../actions/dateFormated';
import { StyledTableCell, StyledTableRow } from '../styles/style';

const cellTableHead = (handleHeaderOrder, content, key)=> {
    return (
        <StyledTableCell key={key}>
            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: Styles.color.darkGrey,
                    padding: 1,
                    cursor: "pointer"
                }}
                onClick={()=>handleHeaderOrder(key)}
            >
                <Box sx={{
                    marginRight: 1,
                    textTransform: 'capitalize'
                }} style={Styles.tableHeaderFont}>
                    { content }
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <ExpandLess fontSize='15px' />
                    <ExpandMore fontSize='15px' />
                </Box>
            </Box>
        </StyledTableCell>
    );
}

export default function ObjectTableStory({ 
    dataHeader, 
    dataBody, 
    isOnload,
    menu,
    handleHeaderOrder
}) {

    return (
        <Box >
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(handleHeaderOrder, content, key))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            dataBody.map(object => (
                                <StyledTableRow key={uuid()}>
                                    {
                                        menu[0].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { object?.visite?.visiteur?.nom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { object?.visite?.visiteur?.prenom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { object?.visite?.visiteur?.societe.denomination || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { object?.typeObjet?.nomobjet || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[4].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { dateFormat(object?.createdAt) || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[5].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { HoursFormat(object?.createdAt) || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[6].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { dateFormat(object?.backedAt) || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[7].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { HoursFormat(object?.backedAt) || "...." }
                                        </StyledTableCell>
                                    }
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}