import API_URL from "./config";
import axios from "axios";
import Construct from "./construct";


class Users extends Construct {

    async login(user, password) {
        try {

            const response = await axios.post(`${API_URL}/login`, {
                "email": user,
                "password": password
            });
            return response;
        }
        catch (err) {
            throw err;
        }
    }

    async getUserByEmail(email) {
        try {
            const response = await this._request.get(`${API_URL}/userBymail/${email}`);
            return response;
        } catch (err) {
            throw err;
        }
    }

    async createJSONUsers(user, isEdit = null) {
        const date = new Date();
        return {
            ...user,
            datecreation: user.datecreation || date.toISOString(),
            datemodif: isEdit ? date.toISOString() : null,
            idclient: "",
            idprofil: null,
            estActif: true
        }
    }

    async setUsers(user) {
        try {
            const response = await this._request.post(`${API_URL}/create/user`, await this.createJSONUsers(user))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async editUsers(user, id) {
        try {
            const response = await this._request.put(`${API_URL}/edit/user/${id}`, await this.createJSONUsers(user))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async deleteUsers(user, id) {
        try {
            const userTemp = await this.createJSONUsers(user);
            const response = await this._request.put(`${API_URL}/edit/user/${id}`, {
                ...userTemp,
                email: `__delete__${id}__${userTemp.email}`,
                estActif: false
            })
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getClients() {
        try {
            const response = await this._request.get(`${API_URL}/users`);
            const { data } = response;
            if (data["hydra:member"].length === 0) return [];
            const clientAdminId = await this.getClientAdmin();
            return await data["hydra:member"].filter(el => 
                { return el?.idclientAdmin === clientAdminId }
            );
        } catch (err) {
            throw err;
        }
    }

    async getUsers() {
        try {
            const response = await this._request.get(`${API_URL}/users`);
            const { data } = response;
            return await data["hydra:member"].filter(data => {
                const role = data?.roles.at(-1) || "";
                return ("ROLE_ADMIN" === role)
            })
        } catch (err) {
            throw err;
        }
    }

}
const users = new Users();
export default users;
