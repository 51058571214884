import { useState } from "react";

export const useVisitorForm = ()=> {
    const visitorJSON = {
        nom: "",
        prenom: "",
        email: "",
        cin: "",
        cinDate: null,
        cinDateEcheance: null,
        formationSecurite: "",
        dateFormation: null,
        dateFormationEcheance: null,
        estActif: true,
        tel: "",
        remarque: "",
        categorieVisiteur: "",
        societe: ""
    }
    const [visitorInput, setVisitorInput] = useState(visitorJSON);
    return { 
        visitorJSON,
        visitorInput,
        setVisitorInput
    }
}