import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Dashbord } from "./dashbord";
import { Admin } from "./adminList/index";
import { AddList } from "./adminList/add";
import { DeleteList } from "./adminList/delete";
import { StoryObject } from "./objectStory";
import { Rapport } from "./rapport";
import { Setting } from "./setting";
import { SignInComponent } from "../authentication/login";
import { Box, Grid } from "@mui/material";
import { Navbar } from "../components/Navbar";
import { NavbarAdmin } from "../components/NavbarAdmin";
import { Header } from "../components/Header";
import AuthProvider from "react-auth-kit/AuthProvider";
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import { store } from "../actions/store";
import { VisitorProvider } from "../actions/visitorContext";
import users from "../actions/users";
import { AddClient } from "./adminSuper/addClient";
import { Clients } from "./adminSuper/clients";
import { Profil } from "./profil";
import { SetClient } from "./setClient";
import { ForgetPassword } from "../authentication/forgot";
import { ChangePassword } from "../authentication/changePassword";
import API_URL from "../actions/config";

export const Index = ()=> {
    const [size, setSize] = useState(window.innerWidth);
    const [openFullMenu, setOpenFullMenu] = useState(size >= 1366);
    const [anchorElAdmin, setAnchorElAdmin] = useState(null);
    const openAdmin = Boolean(anchorElAdmin);
    const [openSetting, setOpenSetting] = useState(false);
    const [active, setActive] = useState();
    const [adminActive, setAdminActive] = useState();
    const [settingActive, setSettingActive] = useState();
    const [modalForm, setModalForm] = useState(false);
    const [onSearch, setOnsearch] = useState("");
    const [badgeOnSearch, setOnSearch] = useState(false);
    const [timoutList, setTimoutList] = useState(0);
    const [fallBackPath, setFallBackPath] = useState("/login");

    const handleOpenForm = ()=> {
        setModalForm(true);
        setOnSearch(false);
        setTimoutList(0);
    }

    const handleOpenAdmin = (ev)=> {
        setAnchorElAdmin(ev.currentTarget);
    }
    const handleCloseAdmin = ()=> {
        setAnchorElAdmin(null);
    }
    const handleOpenSetting = ()=> {
        setOpenSetting(true);
    }
    const handleCloseSetting = ()=> {
        setOpenSetting(false);
    }
    const handleClickFullMenu = ()=> {
        setOpenFullMenu(!openFullMenu);
    }

    const handleClickNav = ()=> {
        if (size >= 1366) return;
        setOpenFullMenu(false);
    }

    const handleActiveChange = (e)=> {
        setActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
        handleCloseAdmin();
        handleCloseSetting();
        setAdminActive();
        setSettingActive();
    }

    const handleadminActiveChange = (e)=> {
        setAdminActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
    }

    const handleSettingActiveChange = (e)=> {
        setSettingActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
    }

    (function useWindowSize() {
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
                setOpenFullMenu(Boolean(window.innerWidth >= 1366));
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
    }());
    window.onclick = (e)=> {
        setOnsearch("");
        if (size >= 1366) return;
        setOpenFullMenu(e.target.closest('.menuList') ? true : false);
    }

    const [accessControl, setAccessControl] = useState([]);
    const token = useMemo(()=> {
        return store.tokenObject.authValue.auth || {};
    }, []);

    useEffect(() => {
        const callIsAdminFunc = async ()=> {
            try {
                const { email } = store.tokenObject.authValue.userState || {};
                const authorizationHeader = token ? `${token.type || 'Bearer'} ${token.token}` : '';
                const { data } = await users.getUserByEmail(email);

                const res = await fetch(`${API_URL}/profils`, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorizationHeader
                    }
                });
                const access = await res.json();
                const accessFilter = access["hydra:member"].filter(el => el.denomination === data?.roles.at(-1))
                .map(el => el.tableAccess);
                const role = (
                    data?.roles.at(-1) === "ROLE_SUPER_ADMIN" || 
                    data?.roles.at(-1) === "ROLE_ADMIN" || 
                    data?.roles.at(-1) === "ROLE_USER") 
                    ? data?.roles.at(-1) : data?.roles.at(0);
                setAccessControl(() => {
                    return [
                        ...accessFilter,
                        role
                    ]
                });
            } catch (err) {
                throw err;
            }
        }
        store.tokenObject.authValue.isSignIn && callIsAdminFunc();
    }, [token])
    return(
        <AuthProvider store={store}>
            <BrowserRouter>
                <Grid container spacing={0}>
                    <RequireAuth fallbackPath={fallBackPath} >
                        {
                            (
                                accessControl?.at(-1)?.includes("ROLE_SUPER_ADMIN") && openFullMenu &&
                                <NavbarAdmin 
                                    size={size}
                                    setOpenFullMenu={setOpenFullMenu}
                                />
                            )
                            ||
                            (
                                (
                                    accessControl?.at(-1)?.includes("ROLE_ADMIN") || 
                                    accessControl?.at(-1)?.includes("ROLE_USER")
                                ) 
                                && openFullMenu &&
                                <Navbar 
                                    openAdmin={openAdmin}
                                    openSetting={openSetting}
                                    handleOpenAdmin={handleOpenAdmin} 
                                    handleCloseAdmin={handleCloseAdmin}
                                    handleOpenSetting={handleOpenSetting}
                                    handleCloseSetting={handleCloseSetting}
                                    active={active}
                                    handleClickNav={handleClickNav}
                                    settingActive={settingActive}
                                    adminActive={adminActive}
                                    handleadminActiveChange={handleadminActiveChange}
                                    handleSettingActiveChange={handleSettingActiveChange}
                                    handleActiveChange={handleActiveChange}
                                    setOnsearch={setOnsearch}
                                    setModalForm={setModalForm}
                                    accessControl={accessControl}
                                />
                            )
                        }
                        <Grid 
                            item
                            sx={{
                                backgroundColor: "#F6F6F6",
                                width: openFullMenu ? 267 : 0
                            }} 
                        ></Grid>
                    </RequireAuth>
                    <Grid 
                        item 
                        xs
                        sx={{
                            backgroundColor: "#F6F6F6",
                            minHeight: "100vh"
                        }}
                    >
                        <RequireAuth fallbackPath={fallBackPath} >
                            <VisitorProvider>
                            {
                                (
                                    accessControl?.at(-1)?.includes("ROLE_SUPER_ADMIN") &&
                                    <>
                                        <Header 
                                            openFullMenu={openFullMenu} 
                                            handleClickFullMenu={handleClickFullMenu} 
                                        />
                                        <Box sx={{
                                            marginTop: 12
                                        }}>
                                            <Routes>
                                                <Route exact path="/" element={<Clients openFullMenu={openFullMenu} />} />
                                                <Route path="/clients" element={<AddClient />} />
                                                <Route path="/change/password" element={<ChangePassword /> } />
                                            </Routes>
                                        </Box>
                                    </>
                                )
                                ||
                                (
                                    (
                                        accessControl?.at(-1)?.includes("ROLE_ADMIN") || 
                                        accessControl?.at(-1)?.includes("ROLE_USER")
                                    ) &&
                                    <>
                                        <Header 
                                            openFullMenu={openFullMenu} 
                                            handleClickFullMenu={handleClickFullMenu} 
                                            setOpenSetting={setOpenSetting}
                                            setActive={setActive}
                                            setModalForm={setModalForm}
                                            onSearch={onSearch}
                                            setOnsearch={setOnsearch}
                                            handleOpenForm={handleOpenForm}
                                            accessControl={accessControl}
                                        />
                                        <Box sx={{
                                            marginTop: 12
                                        }}>
                                            <Routes>
                                                <Route exact path="/" element={
                                                        <Dashbord 
                                                            openFullMenu={openFullMenu} 
                                                            modalForm={modalForm}
                                                            setModalForm={setModalForm}
                                                            badgeOnSearch={badgeOnSearch}
                                                            setOnSearch={setOnSearch}
                                                            timoutList={timoutList}
                                                            setTimoutList={setTimoutList}
                                                            accessControl={accessControl}
                                                        />
                                                    } 
                                                />
                                                <Route path="/admin/:slug" element={
                                                        <Admin 
                                                            openFullMenu={openFullMenu}
                                                            accessControl={accessControl}
                                                        />
                                                    } 
                                                />
                                                <Route path="/add/:slug" element={<AddList />} />
                                                <Route path="/delete/:slug" element={<DeleteList />} />
                                                <Route path="/story/object" element={<StoryObject />} />
                                                <Route path="/rapport" element={
                                                        <Rapport 
                                                            openFullMenu={openFullMenu}
                                                        />
                                                    } 
                                                />
                                                <Route path="/change/password" element={<ChangePassword /> } />
                                                {
                                                    accessControl?.at(-1)?.includes("ROLE_ADMIN") &&
                                                    <>
                                                        <Route path="/setting/users" element={<Setting />} />
                                                        <Route path="/setting/profils" element={<Profil />} />
                                                        <Route path="/clients" element={<SetClient />} />
                                                    </>
                                                }
                                            </Routes>
                                        </Box>
                                    </>
                                )
                            }
                            </VisitorProvider>
                        </RequireAuth>
                        <Routes>
                            <Route path="/login" element={
                                <SignInComponent 
                                    setFallBackPath={setFallBackPath} 
                                /> } 
                            />
                            <Route path="/forgot" element={<ForgetPassword /> } />
                        </Routes>
                    </Grid>
                </Grid>
            </BrowserRouter>
        </AuthProvider>
    )
}