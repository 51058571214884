import { Box, Grid, InputBase, Typography } from "@mui/material";
import { useParams } from "react-router-dom"
import { Styles } from "../../styles/style";
import { InputSelect } from "../../components/Select";
import { ButtonEffect } from "../../components/ButtonIcon";
import { Search } from "@mui/icons-material";
import AdminTable from "../../components/AdminTable";
import { adminData, adminDataTable, filters } from "../../actions/data";
import { Link } from "react-router-dom";
import { ShadowBox } from "../../components/BoxShadow";
import { CustomizePagination } from "../../components/Pagination";
import { slugs } from "../../actions/data";
import {  useEffect, useState } from "react";
import enterprise from "../../actions/enterprise";
import category from "../../actions/category";
import visitor from "../../actions/visitors";
import visitedPersonne from "../../actions/visitedPersonne";
import object from "../../actions/object";
import { paginationFilter } from "../../actions/pagination";
import { ShowColumn } from "../../components/showColumn";
import badges from "../../actions/badges";
import Construct from "../../actions/construct";
import useShowColumn from "../../customHook/useShowColumn";
const construct = new Construct();

const visitorFiltered = (data, input, enterpriseItems = "", categoryItems = "") => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const firstName = el?.prenom ? el?.prenom.toLowerCase() : "";
        const lastName = el?.nom ? el?.nom.toLowerCase() : "";
        const email = el?.email ? el?.email.toLowerCase() : "";
        const enterprise = el?.societe?.denomination ? 
        el?.societe?.denomination.toLowerCase() : "";
        const tel = el?.tel ? el?.tel.toLowerCase() : "";
        const cin = el?.cin ? el?.cin.toLowerCase() : "";
        const category = el?.categorieVisiteur?.libelle ? 
        el?.categorieVisiteur?.libelle.toLowerCase() : "";
        if ((enterpriseItems !== "" && enterpriseItems !== "Tous") && 
            (categoryItems !== "" && categoryItems !== "Tous")) {
            return (
                (
                    firstName.indexOf(search) > -1 ||
                    lastName.indexOf(search) > -1 ||
                    email.indexOf(search) > -1 ||
                    enterprise.indexOf(search) > -1 ||
                    tel.indexOf(search) > -1 ||
                    cin.indexOf(search) > -1 ||
                    category.indexOf(search) > -1
                )   &&
                ((enterpriseItems.toLocaleLowerCase() === enterprise) && 
                (categoryItems.toLocaleLowerCase() === category))
            )
        }
        else if (enterpriseItems !== "" && enterpriseItems !== "Tous") {
            return (
                (
                    firstName.indexOf(search) > -1 ||
                    lastName.indexOf(search) > -1 ||
                    email.indexOf(search) > -1 ||
                    enterprise.indexOf(search) > -1 ||
                    tel.indexOf(search) > -1 ||
                    cin.indexOf(search) > -1 ||
                    category.indexOf(search) > -1
                ) &&
                (enterpriseItems.toLowerCase() === enterprise)
            )
        }
        else if (categoryItems !== "" && categoryItems !== "Tous") {
            return (
                (
                    firstName.indexOf(search) > -1 ||
                    lastName.indexOf(search) > -1 ||
                    email.indexOf(search) > -1 ||
                    enterprise.indexOf(search) > -1 ||
                    tel.indexOf(search) > -1 ||
                    cin.indexOf(search) > -1 ||
                    category.indexOf(search) > -1
                ) &&
                (categoryItems.toLowerCase() === category)
            )
        }
        else {
            return (
                firstName.indexOf(search) > -1 ||
                lastName.indexOf(search) > -1 ||
                email.indexOf(search) > -1 ||
                enterprise.indexOf(search) > -1 ||
                tel.indexOf(search) > -1 ||
                cin.indexOf(search) > -1 ||
                category.indexOf(search) > -1
            )
        }
    })
}

const enterpriseFiltered = (data, input) => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const denomination = el?.denomination ? el?.denomination.toLowerCase() : "";
        return denomination.indexOf(search) > -1;
    })
}

const categoryFiltered = (data, input) => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const denomination = el?.libelle ? el?.libelle.toLowerCase() : "";
        return denomination.indexOf(search) > -1;
    })
}

const visitedFiltered = (data, input) => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const firstName = el?.prenom ? el?.prenom.toLowerCase() : "";
        const lastName = el?.nom ? el?.nom.toLowerCase() : "";
        const email = el?.email ? el?.email.toLowerCase() : "";
        const tel = el?.tel ? el?.tel.toLowerCase() : "";
        return (
            firstName.indexOf(search) > -1 ||
            lastName.indexOf(search) > -1 ||
            email.indexOf(search) > -1 ||
            tel.indexOf(search) > -1
        )
    })
}

const objectFiltered = (data, input) => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const denomination = el?.nomobjet ? el?.nomobjet.toLowerCase() : "";
        return denomination.indexOf(search) > -1;
    })
}

const badgeFiltered = (data, input) => {
    const search = input.toLowerCase();
    return data.filter(el => {
        const denomination = el?.denomination ? el.denomination.toLowerCase() : "";
        const badge = el?.etatbadge ? "pas encore utilisé" : "en cours d'utilisation";
        return (
            denomination.indexOf(search) > -1 ||
            badge.indexOf(search) > -1
        )
    })
}

export const Admin = ({ openFullMenu, accessControl })=> {
    const [enterpriseItems, setEnterpriseItems] = useState("");
    const [categoryItems, setCategoryItems] = useState("");
    const [isOnload, setIsOnload] = useState(true);
    const [isOnSearch, setIsOnSearch] = useState("");
    const [dataBody, setDataBody] = useState([]);
    const { slug } = useParams();
    const id = adminData.findIndex(data => data.link === slug);
    const [pageNumbers, setPageNumbers] = useState(null);
    const [linesNumbers, setLinesNumbers] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);
    const [dataPages, setDataPages] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    const [selectedItems, setSelectedItems] = useState([["Tous"], ["Tous"]]);
    const [HeaderTable, setHeaderTable] = useState([]);
    const [status, setStatus] = useState(true);

    const {
        open,
        anchorEl,
        menu,
        setMenu,
        handleOpenRow,
        handleCloseRow,
        handleShowColumn
    } = useShowColumn(HeaderTable, setHeaderTable);

    const handleEnterpriseItemsChange = (e)=> {
        setEnterpriseItems(e.target.value);
    }

    const handleCategoryItemsChange = (e)=> {
        setCategoryItems(e.target.value);
    }

    const handlePagesNumbers = ()=> {
        const pages = ((status === "Tous") && (!isOnSearch || !selectedItems))
        ? 
            Math.floor((dataBody.length - 1) / checkPagination)
        : 
            Math.floor((dataPages.length - 1) / checkPagination);
        setPageNumbers(pages);
    }

    const handlePaginationActive = (activePage)=> {
        setActivePage(activePage)
    }
    
    const handlePaginationChecked = (checkPagination)=> {
        setCheckPagination(checkPagination)
    }

    const handleStatus = (e)=> {
        const { value } = e.target;
        if (value === "Actif")
            return setStatus(true);
        if (value === "Inactif")
            return setStatus(false);
        setStatus("Tous");
    }

    useEffect(()=> {
        setHeaderTable(()=>
            adminDataTable[id]
        );
        setMenu(()=>
            adminDataTable[id].map(el => {
                return {
                    status: true,
                    value: el
                }
            })
        );
    }, [id, setMenu])

    useEffect(()=> {
        setLinesNumbers(dataFilter.length);
    }, [dataFilter])

    useEffect(()=> {
        const callItemsData = async ()=> {
            const dataEnterprise = await enterprise.getEnterprise();
            const dataCategory = await category.getCategory();
            const _Enterprises = dataEnterprise.map(data => data?.denomination || []);
            const _Categories = dataCategory.map(data => data?.libelle || []);
            setSelectedItems([[..._Enterprises, "Tous"], [..._Categories, "Tous"]]);
        }
        callItemsData()
    }, [slug, dataBody])

    useEffect(()=> {
        const filteredData = async ()=> {
            const data = await construct.isDataActif(dataBody, status);
            setDataPages(data);
            const results = paginationFilter(data, activePage, checkPagination);
            switch (slug) {
                case "Visitor": {
                        setDataFilter(visitorFiltered(results, isOnSearch, enterpriseItems, categoryItems));
                    break;
                }
                case "Visited": {
                        setDataFilter(visitedFiltered(results, isOnSearch))
                    break;
                }
                case "Enterprise": {
                        setDataFilter(enterpriseFiltered(results, isOnSearch))
                    break;
                }
                case "Category": {
                        setDataFilter(categoryFiltered(results, isOnSearch))
                    break;
                }
                case "Object": {
                        setDataFilter(objectFiltered(results, isOnSearch))
                    break;
                }
                case "Badge": {
                        setDataFilter(badgeFiltered(results, isOnSearch))
                    break;
                }
                default: return;
            }
        }
        filteredData();
    }, [
            slug, 
            dataBody, 
            activePage, 
            checkPagination, 
            isOnSearch, 
            enterpriseItems, 
            categoryItems,
            status
        ])

    useEffect(()=> {
        const getEnterprise = async ()=> {
            setIsOnload(true);
            switch (slug) {
                case "Visitor": {
                    const data = await visitor.getVisitorAdmin();
                    setDataBody(data);
                    break;
                }
                case "Visited": {
                    const data = await visitedPersonne.getVisitedPersonneAdmin();
                    setDataBody(data);
                    break;
                }
                case "Enterprise": {
                    const data = await enterprise.getEnterpriseAdmin();
                    setDataBody(data);
                    break;
                }
                case "Category": {
                    const data = await category.getCategoryAdmin();
                    setDataBody(data);
                    break;
                }
                case "Object": {
                    const data = await object.getObjectAdmin();
                    setDataBody(data);
                    break;
                }
                case "Badge": {
                    const data = await badges.getBadgesAdmin();
                        setDataBody(data);
                    break;
                }
                default: return;
            }
            setIsOnload(false);
        }
        setStatus(true);
        getEnterprise();
    }, [slug])

    return(
        <>        
            <ShadowBox>
                <Grid container rowSpacing={1} justifyContent={{
                    xs: "center",
                    md: "space-between",
                }} alignItems="center" sx={{
                    minHeight: 80
                }}>
                    <Grid item sx={Styles.fontTitle} style={{
                        color: Styles.color.blue
                    }}>
                        Liste des {slugs[slug]}
                    </Grid>
                    <Grid item sx={Styles.fontTitle} style={{
                        color: Styles.color.blue
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 1,
                            flexWrap: {
                                xs: "wrap",
                                md: "nowrap"
                            }
                        }}>
                            {
                                slug === "Visitor" &&
                                <>
                                    {filters.map((option, index) => 
                                        <Box key={index} sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginLeft: 1,
                                            marginBottom: 1,
                                            flexWrap: {
                                                xs: "wrap",
                                                md: "nowrap"
                                            }
                                        }}>
                                            <Box sx={{
                                                width: {
                                                    xs: "70px",
                                                    md: "auto"
                                                },
                                                marginRight: {
                                                    xs: '20px',
                                                    lg: 1
                                                }
                                            }}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "1rem",
                                                    fontWeight: 400,
                                                    letterSpacing: "0.5px",
                                                }}>
                                                    {option}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <InputSelect 
                                                    options={selectedItems.at(index)} 
                                                    defaultOption={option}
                                                    handleChangeOption={
                                                        index === 0 ? 
                                                        handleEnterpriseItemsChange : 
                                                        handleCategoryItemsChange
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 1,
                                        marginBottom: 1,
                                        flexWrap: {
                                            xs: "wrap",
                                            md: "nowrap"
                                        }
                                    }}>
                                        <Box sx={{
                                            width: {
                                                xs: "70px",
                                                md: "auto"
                                            },
                                            marginRight: {
                                                xs: '20px',
                                                lg: 1
                                            }
                                        }}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                letterSpacing: "0.5px",
                                            }}>
                                                Status
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <InputSelect 
                                                options={["Actif", "Inactif", "Tous"]}
                                                handleChangeOption={handleStatus}    
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                            {
                                slug !== "Visitor" &&
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: 1,
                                    marginBottom: 1,
                                    flexWrap: {
                                        xs: "wrap",
                                        md: "nowrap"
                                    }
                                }}>
                                    <Box sx={{
                                        width: {
                                            xs: "70px",
                                            md: "auto"
                                        },
                                        marginRight: {
                                            xs: '20px',
                                            lg: 1
                                        }
                                    }}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            letterSpacing: "0.5px",
                                        }}>
                                            Status
                                        </Typography>
                                    </Box>
                                    <Box key={slug}>
                                        <InputSelect 
                                            options={["Actif", "Inactif", "Tous"]}
                                            handleChangeOption={handleStatus}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ color: Styles.color.blue, marginBottom: 20 }}/>
                <Grid container rowSpacing={1} justifyContent="space-between" alignItems="center"
                    sx={{
                        marginBottom: 3
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <Box sx={{
                                marginRight: 1
                            }}>
                                <ShowColumn
                                    open={open}
                                    anchorEl={anchorEl}
                                    handleOpenRow={handleOpenRow}
                                    handleCloseRow={handleCloseRow}
                                    handleShowColumn={handleShowColumn}
                                    menu={menu}
                                />
                            </Box>
                            <Link to={accessControl?.at(0)?.includes('edit' + slugs[slug]) ? `/add/${slug}` : '#'} style={{
                                color: "#869CB0",
                                opacity: accessControl?.at(0)?.includes('edit' + slugs[slug]) ? 1 : 0.5
                            }}>
                                <ButtonEffect>
                                    <Box sx={{
                                        border: "1px solid #869CB0",
                                        borderRadius: 2,
                                        padding: "0 10px",
                                        fontFamily: "Inter",
                                        fontSize: "2.25rem",
                                        fontWeight: 400,
                                        lineHeight: "2.625rem",
                                        letterSpacing: "0em",
                                    }} title="ajout">
                                            +
                                    </Box>
                                </ButtonEffect>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box style={ Styles.boxModel } sx={{
                            minWidth: {
                                xs: 170,
                                md: '24.688rem'
                            },
                            minHeight: {
                                xs: 20,
                                md: '2.8125rem'
                            },
                            display: 'flex',
                            alignItems: 'center',
                            border: `1.5px solid ${Styles.color.grey}`,
                        }}>
                            <Search sx={{
                                marginLeft: 1,
                                color: Styles.color.grey
                            }}/>
                            <InputBase placeholder="Recherche..." 
                                sx={{
                                    fontFamily: "Montserrat",
                                    border: `1px solid transparent`, 
                                    width: "100%",
                                    padding: "0 10px",
                                    color: Styles.color.grey
                                }}
                                value={isOnSearch}
                                onChange={e => setIsOnSearch(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs>
                        <AdminTable 
                            openFullMenu={openFullMenu} 
                            dataHeader={HeaderTable} 
                            isOnload={isOnload} 
                            dataBody={dataFilter}
                            menu={menu}
                            slug={slug} 
                            accessControl={accessControl}
                        />
                    </Grid> 
                </Grid>
            </ShadowBox>
            <Box sx={{
                padding: 5,
                paddingTop: 0
            }}>
                <CustomizePagination 
                    linesNumbers={linesNumbers}
                    pageNumbers={pageNumbers}
                    handlePagesNumbers={handlePagesNumbers}
                    handlePaginationChecked={handlePaginationChecked}
                    handlePaginationActive={handlePaginationActive}
                />
            </Box>
        </>
    )
}