import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Styles } from "../styles/style";

export const Edit = ({ link = "#", state = null, access = true })=> {
    return(
        <Link to={link} state={{
            from: state
        }}>
            <Box sx={{
                padding: 1,
                paddingBottom: 0.5,
                maxHeight: 25,
                backgroundColor: Styles.color.inputRed,
                borderRadius: 2,
                marginLeft: 1,
                cursor: access ? 'pointer' : 'not-allowed',
                opacity: access ? 1 : 0.5
            }} title="editer">
                <img width={20} src={'/assets/icons/edit.png'} alt="Edit" />
            </Box>
        </Link>
    )
}

export const Delete = ({ link = "#", state = null, access = true })=> {
    return(
        <Link to={link} state={{
            from: state
        }}>
            <Box sx={{
                padding: 1,
                paddingBottom: 0.5,
                maxHeight: 25,
                backgroundColor: Styles.color.grey,
                borderRadius: 2,
                marginLeft: 1,
                cursor: access ? 'pointer' : 'not-allowed',
                opacity: access ? 1 : 0.5
            }} title="supprimer">
                <img width={20} src={'/assets/icons/delete.png'} alt="Delete" />
            </Box>
        </Link>
    )
}