import API_URL from "./config";
import Construct from "./construct";

class ObjectRemise extends Construct {

    async _createJSONObjectRemise(objectRemise) {
        const newDate = new Date()
        return await {
            ...objectRemise,
            nombreobjet: 1,
            commentaires: "",
            visite: `/api/visites/${objectRemise.visite}`,
            typeObjet: `/api/typeobjets/${objectRemise.objectType}`,
            createdAt: newDate.toISOString(),
            backedAt: null,
            estActif: true,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setObjectRemise(objectRemise) {
        try {

            const allObjectRemise = await this.getObjectRemise();
            const filterObjectRemise = this.filterObjectRemise(allObjectRemise, objectRemise);

            if (filterObjectRemise.length !== 0) {
                filterObjectRemise.forEach(object => {
                    const jsonObjectRemise = {
                        visite: object.visite.idvisite,
                        objectType: object.typeObjet.idtypeobjet
                    }
                    this.editObjectRemise(jsonObjectRemise, object.idremiseobjet, object.nombreobjet);
                });
                return;
            }

            const response = await this._request.post(`${API_URL}/remiseobjets`, await this._createJSONObjectRemise(objectRemise))
            
            if (response.status !== 201) 
                throw new Error();
            
            const { data } = response;
            const visitedId = data["@id"].split('/').at(-1);

            return visitedId;
        } catch (err) {
            throw err;
        }
    }

    async editObjectRemise(objectRemise, id, isNumberUp = null) {
        try {
            if (isNumberUp) {
                const jsonUpdate = await this._createJSONObjectRemise(objectRemise);
                const response = await this._request.put(`${API_URL}/remiseobjets/${id}`, {
                    ...jsonUpdate,
                    nombreobjet: isNumberUp + 1
                })

                if (response.status !== 200) 
                    throw new Error();
            
                const { data } = response;
                const visitedId = data["@id"].split('/').at(-1);
    
                return visitedId;
            }
            else {
                const newDate = new Date();
                const jsonUpdate = await this._createJSONObjectRemise(objectRemise);
                const response = await this._request.put(`${API_URL}/remiseobjets/${id}`, {
                    ...jsonUpdate,
                    backedAt: newDate.toISOString(),
                })
                if (response.status !== 200) 
                    throw new Error();
            
                const { data } = response;
                const visitedId = data["@id"].split('/').at(-1);
    
                return visitedId;
            }
        } catch (err) {
            throw err;
        }
    }

    async getObjectRemise() {
        try {
            const response = await this._request.get(`${API_URL}/remiseobjets`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    filterObjectRemise (allObjectRemise, objectRemise) {
        const results = allObjectRemise.filter(object => 
            object.visite.idvisite === objectRemise.visite && 
            object.typeObjet.idtypeobjet === objectRemise.objectType &&
            !object?.backedAt
        );
        return results;
    }
}
const objectRemise = new ObjectRemise();
export default objectRemise;
