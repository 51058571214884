import { Box, Grid, Button, Select, MenuItem } from "@mui/material";
import { Styles } from "../styles/style";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import uuid from "react-uuid";

export const CustomizePagination = ({ 
    linesNumbers = null, 
    pageNumbers = null,
    handlePagesNumbers = ()=> {}, 
    handlePaginationChecked = ()=> {}, 
    handlePaginationActive = ()=> {} 
})=> {
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    const pages = Array.from({ length: pageNumbers + 1 }, (_ = 0, v) => v + 1);
    const lengthPage = pages.length;

    const handlePreviewPage = ()=> {
        setActivePage(curr => curr < 2 ? curr : curr-1);
    }
    const handleNextPage = ()=> {
        setActivePage(curr => curr > lengthPage - 1 ? curr : curr+1);
    }
    const handleCheckPagination = (e)=> {
        setCheckPagination(e.target.value);
    }

    useEffect(()=> {
        handlePagesNumbers();
        handlePaginationActive(activePage);
        handlePaginationChecked(checkPagination);
    }, [activePage, checkPagination, handlePagesNumbers, handlePaginationActive, handlePaginationChecked])
    useEffect(()=> {
        if (checkPagination > 1)
            setActivePage(1);
    }, [checkPagination])
    return(
        <Grid 
            container 
            spacing={1} 
            justifyContent="space-between" 
            alignItems="center" 
        >
            <Grid item xs sx={Styles.paginationFont}>{ linesNumbers } lignes</Grid>
            <Grid item xs sx={Styles.paginationFont} textAlign="center">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {
                        lengthPage !== 0 && 
                        <>
                            <Box sx={{
                                marginRight: 1
                            }}>
                                <Button sx={{
                                    backgroundColor: "transparent",
                                    border: '1px solid #ACACAC',
                                    borderRadius: 1.5,
                                    minWidth: 0,
                                    width: 40
                                }} onClick={handlePreviewPage}>
                                    <ArrowLeft color="action" />
                                </Button>
                            </Box>
                            <Box sx={{
                                marginRight: 1,
                                borderRadius: 1
                            }}>
                            <Box sx={{
                                        display: "flex"
                                }}
                            >
                                    {
                                        pages.map(page =>
                                            ((page < activePage + 4 && page > activePage - 4) || (page === 1 || page === lengthPage)) ?
                                            <Box sx={{
                                                marginRight: 1,
                                                color: activePage === page ? Styles.color.inputRed : "#8F8F8F",
                                                fontWeight: activePage === page ? "600" : "500",
                                            }} key={uuid()}>
                                                { page }
                                            </Box> :
                                            <Box sx={{
                                                marginRight: 1,
                                            }} key={uuid()}>
                                                ..
                                            </Box> 
                                        )
                                    }
                            </Box>
                            </Box>
                            <Button sx={{
                                backgroundColor: "transparent",
                                border: '1px solid #ACACAC',
                                borderRadius: 1.5,
                                minWidth: 0,
                                width: 40
                            }} onClick={handleNextPage}>
                                <ArrowRight color="action" />
                            </Button>
                        </>
                    }
                </Box>
            </Grid>
            <Grid item xs sx={Styles.paginationFont} textAlign="end">
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        marginRight: 1
                    }}>
                        Afficher
                    </Box>
                    <Select
                        sx={{
                            width: "8.375rem",
                            height: "2.75rem",
                            textAlign: 'left'
                        }}
                        style={Styles.paginationFont}
                        value={checkPagination}
                        onChange={handleCheckPagination}
                    >
                        <MenuItem value={10}>10 lignes</MenuItem>
                        <MenuItem value={25}>25 lignes</MenuItem>
                        <MenuItem value={50}>50 lignes</MenuItem>
                    </Select>
                </Box>
            </Grid>
        </Grid>
    )
}