import axios from "axios";
import { store } from "./store";

export default class Construct {
    constructor() {
        const { type: _type = "Bearer", token: _token = null } = store.tokenObject.authValue.auth || {};
        this._request = axios.create({
            headers: {
                Authorization: `${_type} ${_token}`
            }
        });
    }
    async getUserId () {
        const { userState: {data: {id: userId }}} = await store.tokenObject.authValue || {};
        return userId;
    }
    async getClientAdmin () {
        const clientAdminId = store.tokenObject.authValue?.userState?.data?.idclientAdmin || null;
        return clientAdminId;
    }
    async isDataAdmin (dataFilter) {
        if (dataFilter["hydra:member"].length === 0) return [];
        const clientAdminId = await this.getClientAdmin();
        return await dataFilter["hydra:member"].filter(data => 
            data?.idclientAdmin === clientAdminId
        );
    }
    async isDataActif (data, status) {
        if (status === "Tous") return data;
        return await data.filter(data => 
            data?.estActif === status
        );
    }
    async isData (dataFilter) {
        if (dataFilter["hydra:member"].length === 0) return [];
        const clientAdminId = await this.getClientAdmin();
        return await dataFilter["hydra:member"].filter(data => 
            data?.estActif   === true && data?.idclientAdmin === clientAdminId
        );
    }
}