import { Grid, List, ListItemButton, ListItemIcon, Box, Typography } from "@mui/material"
import { Link } from "react-router-dom";
import { Styles } from "../styles/style";
import { ChevronLeft, ChevronRight, ListTwoTone, SupervisedUserCircle } from "@mui/icons-material";

const adminData = [
    {
        id: 0,
        title: "Visiteurs",
        link: "Visitor",
        img: "visitor.png"
    },
    {
        id: 1,
        title: "Sociétés",
        link: "Enterprise",
        img: "enterprise.png"
    },
    {
        id: 2,
        title: "Catégories",
        link: "Category",
        img: "category.png"
    },
    {
        id: 3,
        title: "Visités",
        link: "Visited",
        img: "visited.png"
    },
    {
        id: 4,
        title: "Objets",
        link: "Object",
        img: "object.png"
    },
    {
        id: 5,
        title: "Badges",
        link: "Badge",
        img: "badge.png"
    }
];

const dataSetting = [
    {
        title: "Utilisateurs",
        link: "users"
    },
    {
        title: "Profiles",
        link: "profils"
    }
];

export const Navbar = (
    { 
        openAdmin, 
        openSetting,
        handleOpenAdmin, 
        handleCloseAdmin, 
        handleOpenSetting,
        handleCloseSetting,
        active,
        handleClickNav,
        settingActive,
        adminActive,
        handleadminActiveChange,
        handleSettingActiveChange,
        handleActiveChange,
        setModalForm,
        accessControl = []
    }
)=> {

    return (
        <Grid item
            style={{ 
                backgroundColor: "#fff",
                minHeight: "100vh",
                position: "fixed",
                zIndex: 3,
                overflowY: "auto"
            }}>
            <img src={'/assets/icons-nav/Vimasys.png'} alt="Vimasys" style={{
                display: "block",
                margin: "1rem auto 2rem auto",
                padding: "0 15px"
            }} width={240} />
            <List 
                sx={{
                    width: "100%",
                    color: Styles.color.defaultText
                }}
                component="nav"
            >
                <Link to='/' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton style={{
                        color: active === 0 ? Styles.color.inputRed : Styles.color.darkGrey,
                        borderRight: active === 0 ? "4px solid #D9313F" : "none"
                    }} onClick={e => {
                        setModalForm(false)
                        handleActiveChange(e)
                    }} data-active={0} sx={Styles.buttonHover}>
                        {
                            accessControl?.at(0)?.includes("viewDashboard") &&
                            <>
                                <ListItemIcon>
                                    <img 
                                        src={`/assets/icons-nav/${active === 0 ? "red-" : ""}dashboard.png`} 
                                        alt="dashbord" 
                                        width={18}
                                    />
                                </ListItemIcon>
                                <Typography style={Styles.fontTitle}>Dashboard</Typography>
                            </>
                        }
                    </ListItemButton>
                </Link>
                <ListItemButton
                    className="menuList"
                    onClick={(event) => {
                                handleActiveChange(event)
                                openAdmin ? handleCloseAdmin() : handleOpenAdmin(event)
                            }
                        }
                    style={{
                        color: active === 1 ? Styles.color.inputRed : Styles.color.darkGrey
                    }}
                    data-active={1} sx={Styles.buttonHover}>
                    {
                        (accessControl?.at(0)?.includes("viewVisiteurs") ||
                        accessControl?.at(0)?.includes("viewSociétés") ||
                        accessControl?.at(0)?.includes("viewCatégories") ||
                        accessControl?.at(0)?.includes("viewVisités") ||
                        accessControl?.at(0)?.includes("viewObjets") ||
                        accessControl?.at(0)?.includes("viewBadges"))
                        &&
                        <>
                            <ListItemIcon>
                                <img 
                                    src={`/assets/icons-nav/${active === 1 ? "red-" : ""}admin.png`} 
                                    alt="Administration" 
                                    width={15}
                                />
                            </ListItemIcon>
                            <Typography style={Styles.fontTitle} sx={{
                                marginRight: 3
                            }}>Administration</Typography>
                            {
                                openAdmin ? <ChevronLeft /> : <ChevronRight />
                            }
                        </>
                    }
                </ListItemButton>
                {   openAdmin &&
                    <Box>
                        {
                            adminData.map((data, index) => 
                                <Link to={`/admin/${data.link}`} key={data.id} style={Styles.Link} onClick={handleClickNav}>
                                    <ListItemButton sx={{
                                        color: adminActive === index ? Styles.color.inputRed : Styles.color.darkGrey,
                                        borderRight: adminActive === index ? "4px solid #D9313F" : "none",
                                        paddingLeft: 8,
                                    }} onClick={handleadminActiveChange} data-active={index}>
                                        {
                                            accessControl?.at(0)?.includes("view" + data.title) &&
                                            <>
                                                <ListItemIcon>
                                                    <img src={`/assets/icons-nav/${adminActive === index ? "red-" + data.img : data.img}`} 
                                                    alt={data.title} width={17} />
                                                </ListItemIcon>
                                                <Typography style={Styles.fontTitle}>{data.title}</Typography>
                                            </>
                                        }
                                    </ListItemButton>
                                </Link>
                            )
                        }
                    </Box>
                }
                <Link to='/rapport' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton  style={{
                        color: active === 2 ? Styles.color.inputRed : Styles.color.darkGrey,
                        borderRight: active === 2 ? "4px solid #D9313F" : "none"
                    }} onClick={(e) => {
                        handleActiveChange(e)
                    }}  data-active={2} sx={Styles.buttonHover}>
                        {
                            accessControl?.at(0)?.includes("viewRapports") &&
                            <>
                                <ListItemIcon>
                                    <img src={`/assets/icons-nav/${active === 2 ? "red-" : ""}feedback.png`} alt="Rapports" />
                                </ListItemIcon>
                                <Typography style={Styles.fontTitle}>Rapports</Typography>
                            </>
                        }
                    </ListItemButton>
                </Link>
                {
                    accessControl?.at(-1)?.includes("ROLE_ADMIN") &&
                    <ListItemButton
                        className="menuList"
                        onClick={(event) => {
                                    handleActiveChange(event)
                                    openSetting ? handleCloseSetting() : handleOpenSetting()
                                }
                            }
                        style={{
                            color: active === 3 ? Styles.color.inputRed : Styles.color.darkGrey
                        }}
                        data-active={3} sx={Styles.buttonHover}>
                        <ListItemIcon>
                            <img 
                                src={`/assets/icons-nav/${active === 3 ? "red-" : ""}setting.png`} 
                                alt="Administration" 
                                width={15}
                            />
                        </ListItemIcon>
                        <Typography style={Styles.fontTitle} sx={{
                            marginRight: 4.5
                        }}>Paramétrage</Typography>
                        {
                            openSetting ? <ChevronLeft /> : <ChevronRight />
                        }
                    </ListItemButton>
                
                }
                {
                    openSetting &&
                    <Box>
                        {
                            dataSetting.map((data, index) => 
                                <Link to={`/setting/${data.link}`} key={index} style={Styles.Link} onClick={handleClickNav}>
                                    <ListItemButton sx={{
                                        color: settingActive === index ? Styles.color.inputRed : Styles.color.darkGrey,
                                        borderRight: settingActive === index ? "4px solid #D9313F" : "none",
                                        paddingLeft: 8,
                                    }} onClick={handleSettingActiveChange}  data-active={index}>
                                        <ListItemIcon>
                                            {
                                                data.link === "users" && <ListTwoTone sx={{
                                                    color: settingActive === index ? Styles.color.inputRed : Styles.color.darkGrey,
                                                }}/>
                                            }
                                            {
                                                data.link === "profils" && <SupervisedUserCircle sx={{
                                                    color: settingActive === index ? Styles.color.inputRed : Styles.color.darkGrey,
                                                }}/>
                                            }
                                        </ListItemIcon>
                                        <Typography style={Styles.fontTitle}>{data.title}</Typography>
                                    </ListItemButton>
                                </Link>
                            )
                        }
                    </Box>
                }
            </List>
        </Grid>
    )
}