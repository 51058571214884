import API_URL from "./config";
import Construct from "./construct";

class Category extends Construct {

    async _createJSONCategory (category) {
        return {
            libelle: category,
            estActif: true,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setCategory(category) {
        try {
            const response = await this._request.post(`${API_URL}/categorievisiteurs`, await this._createJSONCategory(category))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async editCategory(category, id) {
        try {
            const response = await this._request.put(`${API_URL}/categorievisiteurs/${id}`, await this._createJSONCategory(category));
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getCategory() {
        try {
            const response = await this._request.get(`${API_URL}/categorievisiteurs`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async getCategoryAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/categorievisiteurs`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async deleteCategory(category, id) {
        try {
            const _category = await this._createJSONCategory(category.libelle);
            const response = await this._request.put(`${API_URL}/categorievisiteurs/${id}`, {
                ..._category,
                estActif: false,
            });
            return response;
        } catch (err) {
            throw err;
        }
    }

}
const category = new Category();
export default category;
