import { 
    Grid, 
    List, 
    ListItemButton, 
    ListItemIcon, 
    Box, 
    Typography 
} from "@mui/material"
import { List as ListIcon, Timer } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Styles } from "../styles/style";
import { ButtonEffect } from "./ButtonIcon";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const NavbarAdmin = ({ size, setOpenFullMenu })=> {
    const location = useLocation();
    const active = useMemo(()=> {

        if (location.pathname === "/change/password") return;

        return location.pathname === "/" ? 0 : 1
    }, [location])
    const handleClickNav = ()=> {
        if (size >= 1366) return;
        setOpenFullMenu(false);
    }
    return (
        <Grid item style={{ 
            backgroundColor: "#fff",
            width: 270,
            minHeight: "100vh",
            position: "fixed",
            zIndex: 3,
            overflowY: "auto"
        }}>
            <img src={'/assets/icons-nav/Vimasys.png'} alt="Optimas" width={180} style={{
                display: "block",
                margin: "2rem auto"
            }} />
            <List 
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    color: Styles.color.defaultText
                }}
                component="nav"
            >
                 <Link to='/' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton  style={{
                        backgroundColor: active === 0 ? "#D9313F" : ""
                    }} sx={Styles.buttonHover}>
                        <ListItemIcon>
                            <ListIcon sx={{
                                color: active === 0 ? "#fff" : Styles.color.darkGrey
                            }} />
                        </ListItemIcon>
                        <Typography 
                            sx={Styles.fontTitle} 
                            style={{
                                color: active === 0 ? "#fff" : Styles.color.darkGrey
                            }}
                        >
                            Liste des clients
                        </Typography>
                    </ListItemButton>
                </Link>
                <Link to='/clients' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton style={{
                        backgroundColor: active === 1 ? "#D9313F" : "",
                    }} sx={Styles.buttonHover}>
                        <ListItemIcon>
                            <Timer sx={{
                                color: active === 1 ? "#fff" : Styles.color.darkGrey
                            }} />
                        </ListItemIcon>
                        <Typography 
                            sx={Styles.fontTitle} 
                            style={{
                                color: active === 1 ? "#fff" : Styles.color.darkGrey
                            }}
                        >
                            Ajout client
                        </Typography>
                    </ListItemButton>
                </Link>
            </List>
            <Grid item xs sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 20,
                margin: "auto",
                display: {
                    xs: 'flex',
                    md: 'none'
                }
            }}>
                <Grid container justifyContent="center" alignItems="flex-end">
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "flex-end"
                    }}>
                        <ButtonEffect>
                            <img src={'/assets/icons/question.png'} alt="Question" />
                        </ButtonEffect>
                        <ButtonEffect>
                            <img src={'/assets/icons/gear.png'} alt="Setting" />
                        </ButtonEffect>
                        <ButtonEffect>
                            <img src={'/assets/icons/bell.png'} alt="Notification" />
                        </ButtonEffect>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}