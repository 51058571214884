import { ShadowBox } from "../components/BoxShadow";
import { Styles } from "../styles/style";
import { Grid, Box, Typography, InputBase } from "@mui/material";
import UsersTable from "../components/UsersTable";
import { usersTable } from "../actions/data";
import { useEffect, useState } from "react";
import users from "../actions/users";
import { InputSelect } from "../components/Select";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ButtonEffect } from "../components/ButtonIcon";
import { CustomizePagination } from "../components/Pagination";

const userFilter = (data, input)=> {
    const searchInput = input.toLowerCase();
    const results = data.filter(data => {
        const lastName = data?.nomagent ?  data.nomagent.toLowerCase() : "";
        const firstName = data?.prenomagent ?  data.prenomagent.toLowerCase() : "";
        const email = data?.email ?  data.email.toLowerCase() : "";
        const tel = data?.telagent ?  data.telagent.toLowerCase() : "";
        const matricule = data?.matriculeagent ?  data.matriculeagent.toLowerCase() : "";
        const role = data?.roles ?  data?.roles.at(-1) === "ROLE_USER" ? "agent" : data?.roles.at(-1) === "ROLE_ADMIN" ? "admin" : data?.roles.at(-1)?.toLowerCase() : "";
        return (
            lastName.indexOf(searchInput) > -1 ||
            firstName.indexOf(searchInput) > -1 ||
            email.indexOf(searchInput) > -1 ||
            tel.indexOf(searchInput) > -1 ||
            matricule.indexOf(searchInput) > -1 ||
            role.indexOf(searchInput) > -1
        )
    })
    return results;
}

export const Setting = ({ openFullMenu })=> {
    const [pageNumbers, setPageNumbers] = useState(null);
    const [linesNumbers, setLinesNumbers] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    const [isOnSearch, setIsOnSearch] = useState("");
    const [isOnload, setIsOnload]= useState(true);
    const [isDeleteUser, setIsDeleteUser] = useState(1);
    const [dataBody, setDataBody] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [status, setStatus] = useState(true);

    const handleDeleteUser = ()=> {
        setIsDeleteUser(prev => (prev * -1))
    }

    const handlePagesNumbers = ()=> {
        const pages = (
            isOnSearch && status !== null
        ) ?
            Math.floor((dataFilter.length - 1) / checkPagination)
        :
            Math.floor((dataBody.length - 1) / checkPagination);

        setPageNumbers(pages);
    }

    const handlePaginationActive = (activePage)=> {
        setActivePage(activePage)
    }
    
    const handlePaginationChecked = (checkPagination)=> {
        setCheckPagination(checkPagination)
    }

    const handleStatus = (e)=> {
        const { value } = e.target;
        if (value === "Actif")
            return setStatus(true);
        if (value === "Inactif")
            return setStatus(false);
        setStatus("Tous");
    }

    useEffect(()=> {
        setLinesNumbers(dataFilter.length);
    }, [activePage, dataFilter])

    useEffect(()=> {
        const filtered = async ()=> {
            const data = await users.isDataActif(dataBody, status);
            setDataFilter(userFilter(data, isOnSearch));
        }
        filtered();
    }, [isOnSearch, dataBody, status])

    useEffect(()=> {
        const callUsersGetter = async ()=> {
            setIsOnload(true);
            const data = await users.getClients();
            setDataBody(data);
            setIsOnload(false);
        }
        callUsersGetter();
    }, [isDeleteUser])
    return(
        <>
            <ShadowBox>
                <Grid container rowSpacing={1} justifyContent={{
                        xs: "center",
                        md: "space-between",
                    }} alignItems="center" sx={{
                        minHeight: 80
                    }}>
                    <Grid item sx={Styles.fontTitle} style={{
                            color: Styles.color.blue
                    }}>
                        Liste des utilisateurs
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 1,
                        marginBottom: 1,
                        flexWrap: {
                            xs: "wrap",
                            md: "nowrap"
                        }
                    }}>
                        <Box sx={{
                            width: {
                                xs: "70px",
                                md: "auto"
                            },
                            marginRight: {
                                xs: '20px',
                                lg: 1
                            }
                        }}>
                            <Typography sx={{
                                fontFamily: "Montserrat",
                                fontSize: "1rem",
                                fontWeight: 400,
                                letterSpacing: "0.5px",
                            }}>
                                Status
                            </Typography>
                        </Box>
                        <Box>
                            <InputSelect 
                                options={["Actif", "Inactif", "Tous"]}
                                handleChangeOption={handleStatus}
                            />
                        </Box>
                    </Box>
                </Grid>
                <hr style={{ color: Styles.color.blue, marginBottom: 20 }}/>
                <Grid container rowSpacing={1} justifyContent="space-between" alignItems="center"
                    sx={{
                        marginBottom: 3
                    }}
                >
                    <Grid item>
                        <Link to={`/clients`} style={{
                            color: "#869CB0"
                        }}>
                            <ButtonEffect>
                                <Box sx={{
                                    border: "1px solid #869CB0",
                                    borderRadius: 2,
                                    padding: "0 10px",
                                    fontFamily: "Inter",
                                    fontSize: "2.25rem",
                                    fontWeight: 400,
                                    lineHeight: "2.625rem",
                                    letterSpacing: "0em",
                                }} title="ajout">
                                        +
                                </Box>
                            </ButtonEffect>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Box style={ Styles.boxModel } sx={{
                            minWidth: {
                                xs: 170,
                                md: '24.688rem'
                            },
                            minHeight: {
                                xs: 20,
                                md: '2.8125rem'
                            },
                            display: 'flex',
                            alignItems: 'center',
                            border: `1.5px solid ${Styles.color.grey}`,
                        }}>
                            <Search sx={{
                                marginLeft: 1,
                                color: Styles.color.grey
                            }}/>
                            <InputBase placeholder="Recherche..." 
                                sx={{
                                    fontFamily: "Montserrat",
                                    border: `1px solid transparent`, 
                                    width: "100%",
                                    padding: "0 10px",
                                    color: Styles.color.grey
                                }}
                                value={isOnSearch}
                                onChange={e => setIsOnSearch(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs>
                        <UsersTable 
                            isOnload={isOnload} 
                            openFullMenu={openFullMenu} 
                            dataHeader={usersTable} 
                            dataBody={dataFilter}
                            handleDeleteUser= {handleDeleteUser}
                        />
                    </Grid> 
                </Grid>
            </ShadowBox>
            <Box sx={{
                padding: 5,
                paddingTop: 0
            }}>
                <CustomizePagination 
                    linesNumbers={linesNumbers}
                    pageNumbers={pageNumbers}
                    handlePagesNumbers={handlePagesNumbers}
                    handlePaginationChecked={handlePaginationChecked}
                    handlePaginationActive={handlePaginationActive}
                />
            </Box>
        </>
    )
}