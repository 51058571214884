import { Button } from "@mui/material"

export const ComponentButton = ({ 
    children, 
    lineHeight, 
    backgroundColor,
    color, 
    type = "submit", 
    onClick,
    title = "",
    disabled = false
})=> {
    return (
        <Button variant="contained" style={{
            fontFamily: "Montserrat",
            fontSize: "1.313rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            textAlign: "center",
            textTransform: "capitalize",
            lineHeight,
            backgroundColor,
            color
        }} 
            type={type}
            onClick={onClick}
            title={title}
            disabled={disabled}
        >
            { children }
        </Button>
    )
}