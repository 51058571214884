import { Grid, Typography, TextField, Button, Box, Modal } from "@mui/material";
import { Styles } from "../styles/style";
import { useState } from "react";

export const CustomModal = ({
    title,
    handleSubmit,
    handleIsSubmited,
    handleOpenBackdrop,
    handleCloseBackdrop
})=> {
    const [validation, setValidation] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = ()=> {
        setOpen(true);
    }
    const handleClose = ()=> {
        setOpen(false);
    }
    return(
       <>
            <Grid item>
                <Button variant="outlined" sx={{
                    minWidth: "2.5rem",
                    color: "#AEAEAE",
                    border: "1px solid #869CB0",
                    borderRadius: 2,
                    padding: "0 10px",
                    fontFamily: "Inter",
                    fontSize: "2.25rem",
                    fontWeight: 400,
                    lineHeight: "2.625rem",
                    letterSpacing: "0em",
                }} onClick={handleOpen}>+</Button>
            </Grid>
            <ModalForm 
                title={title}
                handleSubmit={handleSubmit}
                handleIsSubmited={handleIsSubmited}
                handleOpenBackdrop={handleOpenBackdrop}
                handleCloseBackdrop={handleCloseBackdrop}
                validation={validation}
                setValidation={setValidation}
                open={open} 
                handleClose={handleClose} 
            />
       </>
    )
}
export const AddModal = ({
    title,
    handleSubmit,
    handleIsSubmited,
    handleOpenBackdrop,
    handleCloseBackdrop
})=> {
    const [validation, setValidation] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = ()=> {
        setOpen(true);
    }
    const handleClose = ()=> {
        setOpen(false);
    }
    return(
       <>
            <Grid item>
                <Button variant="outlined" sx={{
                    color: "#AEAEAE",
                    border: "1px solid #DDD",
                    minWidth: "3rem",
                    fontFamily: "Inter",
                    fontSize: "2.25rem",
                    fontWeight: 400,
                    lineHeight: "2.5rem",
                    letterSpacing: 0,
                    textAlign: "left",
                    padding: 0
                }} onClick={handleOpen}>+</Button>
            </Grid>
            <ModalForm 
                title={title}
                handleSubmit={handleSubmit}
                handleIsSubmited={handleIsSubmited}
                handleOpenBackdrop={handleOpenBackdrop}
                handleCloseBackdrop={handleCloseBackdrop}
                validation={validation}
                setValidation={setValidation}
                open={open} 
                handleClose={handleClose} 
            />
       </>
    )
}

const ModalForm = ({ 
    title,
    handleSubmit,
    handleIsSubmited,
    handleOpenBackdrop,
    handleCloseBackdrop,
    validation,
    setValidation,
    open, 
    handleClose
})=> {
    return(
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={Styles.modals}>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item sx={Styles.fontTitle}>
                        Ajout { title }
                    </Grid>
                </Grid>
                <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Grid container direction="row">
                            <Typography style={ Styles.typography }>
                                Dénomination *
                            </Typography>
                            <TextField placeholder="Dénomination"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                onChange={e => setValidation(e.target.value)}
                                value={validation}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{
                    marginY: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end"
                }}>
                    <Button variant="contained" 
                        style={Styles.fontDefaultButton}
                        sx={{
                            backgroundColor: "#8D9496",
                            marginRight: 2,
                            width: "100%",
                            "&:hover": {
                                backgroundColor: "#8D9496"
                        }
                    }} onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="contained" 
                        style={Styles.fontDefaultButton}
                        sx={{
                            backgroundColor: Styles.color.red,
                            width: "100%",
                            "&:hover": {
                                backgroundColor: Styles.color.red
                            }
                        }} 
                    onClick={()=> {
                        if (!validation) return;
                        handleOpenBackdrop?.();
                        const handling = async ()=> {
                            await handleSubmit(validation);
                        }
                        const restPattern = async ()=> {
                            await handling();
                            handleIsSubmited();
                            setValidation("");
                            handleClose();
                            handleCloseBackdrop?.();
                        }
                        restPattern();
                    }}>
                        Valider
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}