import { enFormatDate, dateFormat } from "./dateFormated";

export const startIn = (data, startAt)=> {
    return data.filter(object => {
        const createdAt = object?.createdAt ? 
        new Date(enFormatDate(dateFormat(object?.createdAt))).getTime() : null;
        return (
            enFormatDate(startAt).getTime() <= createdAt
        )
    })
}

export const EndIn = (data, endAt)=> {
    return data.filter(object => {
        const backedAt = object?.backedAt ? 
        new Date(enFormatDate(dateFormat(object?.backedAt))).getTime() : null;
        return (
            enFormatDate(endAt).getTime() >= backedAt && backedAt
        )
    })
}

export const FilterStory = (data = [], input = "", startAt = null, endAt = null)=> {
    let results = data;
    if (input) {
        const search = input.toLowerCase();
        results = data.filter(el => {
            const firstName = el?.visite?.visiteur?.prenom.toLowerCase();
            const lastName = el?.visite?.visiteur?.nom.toLowerCase();
            const enterprise = el?.visite?.visiteur?.societe.denomination.toLowerCase();
            const object = el?.typeObjet?.nomobjet.toLowerCase();
            return (
                firstName.indexOf(search) > -1 ||
                lastName.indexOf(search) > -1 ||
                enterprise.indexOf(search) > -1 ||
                object.indexOf(search) > -1
            )
        })
    }
    
    if (startAt) results = startIn(results, startAt);

    if (endAt) results = EndIn(results, endAt);

    return results;
}