import API_URL from "./config";
import Construct from "./construct";

class Visitor extends Construct {

    async _createJSONVisitor(visitor) {
        return await {
            ...visitor,
            societe: `/api/societes/${visitor?.societe}`,
            categorieVisiteur: `/api/categorievisiteurs/${visitor?.categorieVisiteur}`,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setVisitor(visitor) {
        try {
            const response = await this._request.post(`${API_URL}/visiteurs`, await this._createJSONVisitor(visitor))
            
            if (response.status !== 201) 
                throw new Error();
            
            const { data } = response;
            const visitorId = data["@id"].split('/').at(-1);

            return visitorId;
        } catch (err) {
            throw err;
        }
    }

    async editVisitor(visitor, id) {
        try {
            const response = await this._request.put(`${API_URL}/visiteurs/${id}`, await this._createJSONVisitor(visitor))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getVisitor() {
        try {
            const response = await this._request.get(`${API_URL}/visiteurs`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async getVisitorAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/visiteurs`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async filterVisitor(input) {
        try {
            const search = input.toLowerCase();
            const data = await this.getVisitor();
            const result = data.filter(el => {
                const lastName = el.nom.toLowerCase();
                const firstName = el.prenom.toLowerCase();
                const cin = el.cin.toLowerCase();
                return (
                    lastName.indexOf(search) > -1 || 
                    firstName.indexOf(search) > -1 || 
                    cin.indexOf(search) > -1
                )
            });
            return result;
        } catch (err) {
            throw err;
        }
    }

    async deleteVisitor(visitor, id) {
        try {
            const _visitor = await this._createJSONVisitor(visitor);
            const response = await this._request.put(`${API_URL}/visiteurs/${id}`, {
                ..._visitor,
                cinDate: null,
                cinDateEcheance: null,
                formationSecurite: "",
                dateFormation: null,
                dateFormationEcheance: null,
                societe: `/api/societes/${visitor?.societe?.idsociete}`,
                categorieVisiteur: `/api/categorievisiteurs/${visitor?.categorieVisiteur?.idcategorievisiteur}`,
                estActif: false
            })
            return response;
        } catch (err) {
            throw err;
        }
    }
    
}
const visitor = new Visitor();
export default visitor;
