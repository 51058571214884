import { Box, Modal, List, ListItem, Divider, Typography, Grid } from "@mui/material";
import { Visibility, Close } from "@mui/icons-material";
import { useState } from "react";
import { Styles } from "../styles/style";
import { HoursFormat, dateFormat } from "../actions/dateFormated";

export const ShowModal = ({ data }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = ()=> {
        setOpen(true);
    }
    const handleclose = ()=> {
        setOpen(false);
    }
    return(
        <>
            <Box sx={{
                    padding: "5px 6px 2px 6px",
                    maxHeight: 28,
                    border: `2px solid orange`,
                    borderRadius: 1,
                    marginLeft: 1,
                    cursor: 'pointer'
                }} 
                title="Voir détail"
                onClick={handleOpen}
            >
                <Visibility style={{
                    color: "orange"
                }} />
            </Box>
            <ModalView 
                data={data} 
                open={open} 
                handleclose={handleclose} 
            />
        </>
    )
}

export const ModalView = ({ data, open, handleclose })=> {
    return(
        <Modal
            open={open}
            onClose={handleclose}
        >
            <List 
                sx={{
                    position: "absolute",
                    backgroundColor: "#ddd",
                    maxWidth: 400,
                    maxHeight: 440,
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                    borderRadius: 1.5,
                    overflowY: "auto",
                    "&:focus-visible": {
                        outline: "none"
                    }
                }}
            >
                <ListItem>
                    <Grid container columnSpacing={1} justifyContent="space-between" justifyItems="center">
                        <Grid item>
                            <Typography style={Styles.fontTitle}>
                                Détails visite
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Close sx={{
                                    cursor: "pointer",
                                    border: "1px solid transparent",
                                    borderRadius: 4,
                                    "&:hover": {
                                        border: `1px solid ${Styles.color.dark}`,
                                    }
                                }}
                                onClick={handleclose}
                                titleAccess="Fermer"
                            />
                        </Grid>
                    </Grid>
                </ListItem>
                <Divider variant="fullWidth" component="li" sx={{
                    marginBottom: 1
                }}/>
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> N° badge : </Box>
                        { data?.numvisiteur }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Nom : </Box>
                        { data?.visiteur?.nom }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Prénom : </Box>
                        { data?.visiteur?.prenom }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> CIN : </Box>
                        { data?.visiteur?.cin }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Société : </Box>
                        { data?.visiteur?.societe?.denomination }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Catégorie : </Box>
                        { data?.visiteur?.categorieVisiteur?.libelle }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Date Entrée : </Box>
                        { 
                            (dateFormat(data?.dateEntre) || "....") + " à " + 
                            (HoursFormat(data?.dateEntre) || "....")
                        }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Date Sortie : </Box>
                        { 
                            (dateFormat(data?.dateSortie) || "....") + " à " + 
                            (HoursFormat(data?.dateSortie) || "....")
                        }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                    <Typography style={Styles.inputDefaultTypo}>
                        <Box fontWeight='fontWeightMedium' display='inline'> Nombre d'objet affecté : </Box>
                        { data?.object?.length }
                    </Typography>
                </ListItem>
                <Divider variant="middle" component="li" />
            </List>
        </Modal>
    )
}