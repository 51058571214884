import { Box, Grid, InputBase } from "@mui/material";
import { Styles } from "../styles/style";
import ObjectTableStory from "../components/ObjectStoryTable";
import { objectStory } from "../actions/data";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { ShadowBox } from "../components/BoxShadow";
import { Search } from "@mui/icons-material";
import { CustomizePagination } from "../components/Pagination";
import { CustomDatePicker } from "../components/CustomDatePicker";
import { SameTop } from "../components/SameTop";
import { useEffect, useState } from "react";
import objectRemise from "../actions/objectRemise";
import { paginationFilter } from "../actions/pagination";
import { FilterStory } from "../actions/storyFilter";
import { ShowColumn } from "../components/showColumn";
import { HoursFormat, dateFormat } from "../actions/dateFormated";
import useShowColumn from "../customHook/useShowColumn";

export const StoryObject = ()=> {
    const [isOnload, setIsOnload] = useState(true);
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);
    const [isOnSearch, setIsOnSearch] = useState("");
    const [dataBody, setDataBody] = useState([]);
    const [pageNumbers, setPageNumbers] = useState(null);
    const [linesNumbers, setLinesNumbers] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    const [orderBy, setOrderBy] = useState(()=>{
        return {
            asc: 1,
            count: -1
        }
    });
    const [HeaderTable, setHeaderTable] = useState(()=>objectStory);

    const {
        open,
        anchorEl,
        menu,
        handleOpenRow,
        handleCloseRow,
        handleShowColumn
    } = useShowColumn(HeaderTable, setHeaderTable);

    const handleStartAtChange = (date)=> {
        try {
            setStartAt(date.$d.toISOString());
        } catch (err) {
            setStartAt(null);
        }
    }

    const handleEndAtChange = (date)=> {
        try {
            setEndAt(date.$d.toISOString());
        } catch (err) {
            setEndAt(null);
        }
    }

    const handlePagesNumbers = ()=> {
        const pages = (isOnSearch || startAt || endAt)
        ?
            Math.floor((dataFilter.length - 1) / checkPagination)
        :
            Math.floor((dataBody.length - 1) / checkPagination);

        setPageNumbers(pages);
    }

    const handlePaginationActive = (activePage)=> {
        setActivePage(activePage)
    }
    
    const handlePaginationChecked = (checkPagination)=> {
        setCheckPagination(checkPagination)
    }

    const handleHeaderOrder = (index)=> {

        const nextState = {
            ...orderBy,
            count: index,
            asc: orderBy.count === index 
                ? orderBy.asc * -1
                : orderBy.asc
        }

        setOrderBy(nextState);

        const dataOrdered = [...dataFilter];

        switch(index) {
            case 0:
                dataOrdered.sort((a, b) => {
                    if (a?.visite?.visiteur?.nom < b?.visite?.visiteur?.nom) {
                        return - nextState.asc;
                    }
                    if (a?.visite?.visiteur?.nom > b?.visite?.visiteur?.nom) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 1: 
                dataOrdered.sort((a, b) => {
                    if (a?.visite?.visiteur?.prenom < b?.visite?.visiteur?.prenom) {
                        return - nextState.asc;
                    }
                    if (a?.visite?.visiteur?.prenom > b?.visite?.visiteur?.prenom) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 2: 
                dataOrdered.sort((a, b) => {
                    if (a?.visite?.visiteur?.societe.denomination < b?.visite?.visiteur?.societe.denomination) {
                        return - nextState.asc;
                    }
                    if (a?.visite?.visiteur?.societe.denomination > b?.visite?.visiteur?.societe.denomination) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 3: 
                dataOrdered.sort((a, b) => {
                    if (a?.typeObjet?.nomobjet < b?.typeObjet?.nomobjet) {
                        return - nextState.asc;
                    }
                    if (a?.typeObjet?.nomobjet > b?.typeObjet?.nomobjet) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 4:
                dataOrdered.sort((a, b) => {
                    if (dateFormat(a?.createdAt) < dateFormat(b?.createdAt)) {
                        return - nextState.asc;
                    }
                    if (dateFormat(a?.createdAt) > dateFormat(b?.createdAt)) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 5: 
                dataOrdered.sort((a, b) => {
                    if (HoursFormat(a?.createdAt) < HoursFormat(b?.createdAt)) {
                        return - nextState.asc;
                    }
                    if (HoursFormat(a?.createdAt) > HoursFormat(b?.createdAt)) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 6: 
                dataOrdered.sort((a, b) => {
                    if ((dateFormat(a?.backedAt) || "" ) < (dateFormat(b?.backedAt) || "")) {
                        return - nextState.asc;
                    }
                    if ((dateFormat(a?.backedAt) || "" ) > (dateFormat(b?.backedAt) || "")) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 7: 
                dataOrdered.sort((a, b) => {
                    if ((HoursFormat(a?.backedAt) || "") < (HoursFormat(b?.backedAt) || "")) {
                        return - nextState.asc;
                    }
                    if ((HoursFormat(a?.backedAt) || "") > (HoursFormat(b?.backedAt) || "")) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            default:
                return setDataFilter(dataFilter);
        }
    }

    useEffect(()=>{
        setIsOnload(true);
        const callGetObjectRemise = async ()=> {
            const data = await objectRemise.getObjectRemise();
            setDataBody(data);
            setIsOnload(false);
        }
        callGetObjectRemise();
    }, [])
    useEffect(()=> {
        setLinesNumbers(dataFilter.length);
    }, [dataFilter])
    
    useEffect(()=> {
        const results = paginationFilter(dataBody, activePage, checkPagination);
        setDataFilter(FilterStory(results, isOnSearch, startAt, endAt));
    }, [dataBody, activePage, checkPagination, isOnSearch, startAt, endAt])
    return(
        <>
            <ShadowBox>
                <SameTop>
                    <Grid item  sx={Styles.fontTitle} style={{
                        color: Styles.color.blue
                    }}>
                        Historique Objet 
                    </Grid>
                </SameTop>
                <hr style={{ color: Styles.color.blue, marginBottom: 20 }}/>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item xs={3} md={1}>
                        <ShowColumn
                            open={open}
                            anchorEl={anchorEl}
                            handleOpenRow={handleOpenRow}
                            handleCloseRow={handleCloseRow}
                            handleShowColumn={handleShowColumn}
                            menu={menu}
                        />
                    </Grid>
                    <Grid item xs sx={{
                        marginTop: 1.5
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                md: 'flex-end'
                            },
                            flexWrap: "wrap"
                        }}>
                            <Box 
                                sx={{
                                    marginRight: 1,
                                    marginBottom: 1
                                }}
                            >
                                <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"    
                                >
                                    <CustomDatePicker onChange={handleStartAtChange}>Début</CustomDatePicker>
                                </LocalizationProvider>
                            </Box> 
                            <Box 
                                sx={{
                                    marginRight: 1,
                                    marginBottom: 1
                                }}
                            >
                                <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"    
                                >
                                   <CustomDatePicker onChange={handleEndAtChange}>Fin</CustomDatePicker>
                                </LocalizationProvider>
                            </Box> 
                            <Box style={ Styles.boxModel } sx={{
                                minWidth: {
                                    xs: 170,
                                    md: '24.688rem'
                                },
                                minHeight: {
                                    xs: 20,
                                    md: '2.125rem'
                                },
                                display: 'flex',
                                alignItems: 'center',
                                border: `1.5px solid ${Styles.color.grey}`,
                                padding: 0.25
                            }}>
                                <Search sx={{
                                    marginLeft: 1,
                                    color: Styles.color.grey
                                }}/>
                                <InputBase placeholder="Recherche..." 
                                    sx={{
                                        fontFamily: "Montserrat",
                                        border: `1px solid transparent`, 
                                        width: "100%",
                                        padding: "0 10px",
                                        color: Styles.color.grey
                                    }}
                                    value={isOnSearch}
                                    onChange={e => setIsOnSearch(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0} justifyContent="center" sx={{
                    marginTop: 2
                }}>
                    <Grid item xs>
                        <ObjectTableStory 
                            dataHeader={HeaderTable} 
                            dataBody={dataFilter} 
                            isOnload={isOnload}
                            menu={menu}
                            handleHeaderOrder={handleHeaderOrder}
                        />
                    </Grid> 
                </Grid>
            </ShadowBox>
            <Box sx={{
                padding: 5,
                paddingTop: 0
            }}>
                <CustomizePagination 
                    linesNumbers={linesNumbers}
                    pageNumbers={pageNumbers}
                    handlePagesNumbers={handlePagesNumbers}
                    handlePaginationChecked={handlePaginationChecked}
                    handlePaginationActive={handlePaginationActive}
                />
            </Box>
        </>
    )
}