import { DatePicker } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";
import { Styles } from "../styles/style";

export const CustomDatePicker = ({ children, onChange = ()=> {} }) => {
    return(
        <DatePicker label={<Typography sx={{
            fontFamily: "Montserrat",
            fontSize: "inherit"
        }}>{ children }</Typography>}
                slotProps={{ textField: { size: 'small',   sx: { 
                        width: "11rem", 
                        "& .MuiOutlinedInput-input": Styles.inputDefaultTypo                           
                    } 
                } 
            }}
            onChange={onChange}
        />
    )
}