import { 
    Box, 
    Grid, 
    Typography, 
    TextField, 
    Button, 
    CircularProgress
} from '@mui/material';
import { Styles } from '../styles/style';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightAlt } from '@mui/icons-material';
import { store } from "../actions/store";
import axios from 'axios';
import API_URL from '../actions/config';

export const ForgetPassword = () => {
    const navigate = useNavigate();
    const isAuth = store.tokenObject.authValue.isSignIn;
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    
    const handleSubmit = async (e)=> {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await axios.post(`${API_URL}/security/reset-password`, {
                email: email
            });
            const { code } = res.data;
            if (code === 200)
             return setMessage("Veuillez confirmer votre email");
            
            setMessage("Votre email n'existe pas");
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(()=> {
        if(isAuth) navigate('/');
    }, [isAuth, navigate])

    return (
        <Box sx={{
            background: `url(${'/assets/images/fond.png'})`,
            width: "100%",
            height: "100%"
        }}>
            <Box sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: {
                    xs: 200,
                    sm: 300,
                    md: 350,
                    lg: 380,
                    xl: 400
                },
                height: 420,
                margin: "auto",
                boxShadow: "0px 4px 20px 0px #0000001A",
                backgroundColor: "#fff",
                borderRadius: 1.5,
                padding: "1.5rem"
            }}>
                <img 
                    src={'/assets/icons-nav/Vimasys.png'} 
                    alt="visitors" 
                    width={200}
                    style={{
                        display: "block",
                        margin: "1rem auto"
                    }}
                />
                <form onSubmit={handleSubmit} style={{
                    height: "65%"
                }}>
                    <Grid 
                        container
                        spacing={1}
                        alignItems="center"
                        sx={{
                            height: "100%"
                        }}
                    >
                        <Grid item xs>
                            {
                                message &&
                                <Grid container sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "1rem",
                                    color: message === "Votre email n'existe pas" ? 
                                    Styles.color.red : "#009900",
                                    justifyContent: "center",
                                    marginBottom: 5
                                }}>
                                    { message }
                                </Grid>
                            }
                            <Typography style={ Styles.typography }>
                                Entrez votre email
                            </Typography>
                            <TextField placeholder="email"  sx={{ width: '100%' }}
                                InputProps={{
                                    sx: Styles.propsTextField,
                                    type: "email"
                                }}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                            <Button sx={{
                                backgroundColor: Styles.color.blue,
                                border: `1px solid ${Styles.color.blue}`,
                                width: "100%",
                                marginTop: 3,
                                textTransform: "capitalize",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: Styles.color.blue,
                                    color: "#ddd"
                                }
                            }} type='submit'>
                                {
                                    loading ?
                                    <CircularProgress color='secondary' size={20} sx={{
                                        marginRight: 1.5
                                    }}/> :
                                    <ArrowRightAlt sx={{
                                        marginRight: 1
                                    }}/>
                                }
                                Envoyer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
}