import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { Styles } from '../styles/style';
import { LoadingData, NullData } from './Loading';
import { Delete, Edit } from './ButtonAction';
import { StyledTableCell, StyledTableRow } from '../styles/style';

const cellTableHead = (content, key)=> {
    return (
        <StyledTableCell key={key} align='left'>
            <Box sx={{
                color: Styles.color.darkGrey,
                textTransform: 'capitalize',
                padding: 1
            }} style={Styles.tableHeaderFont}>
                { content }
            </Box>
        </StyledTableCell>
    );
}

export default function AdminTable({ 
    openFullMenu, 
    dataHeader, 
    isOnload, 
    dataBody, 
    menu, 
    slug,
    accessControl
}) {

    const editVisitor = accessControl?.at(0)?.includes("editVisiteurs");
    const editEnterprise = accessControl?.at(0)?.includes("editSociétés");
    const editCategory = accessControl?.at(0)?.includes("editCatégories");
    const editVisited = accessControl?.at(0)?.includes("editVisités");
    const editObject = accessControl?.at(0)?.includes("editObjets");
    const editBadge = accessControl?.at(0)?.includes("editBadges");

    const deleteVisitor = accessControl?.at(0)?.includes("deleteVisiteurs");
    const deleteEnterprise = accessControl?.at(0)?.includes("deleteSociétés");
    const deleteCategory = accessControl?.at(0)?.includes("deleteCatégories");
    const deleteVisited = accessControl?.at(0)?.includes("deleteVisités");
    const deleteObject = accessControl?.at(0)?.includes("deleteObjets");
    const deleteBadge = accessControl?.at(0)?.includes("deleteBadges");

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 420 : window.innerWidth;

    return (
        <Box style={{
            maxWidth: `${maxWidth}px`
        }}>
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(content, key))}
                            <StyledTableCell align='right' style={Styles.tableHeaderFont}>
                                <Box sx={{
                                    marginRight: 3,
                                    padding: 1
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            slug === "Visitor" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.nom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.prenom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.societe?.denomination || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.email || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[4]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.tel || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[5]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.cin || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[6]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.categorieVisiteur?.libelle || "...." }
                                        </StyledTableCell>
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit 
                                                link={editVisitor ? `/add/${slug}` : '#'} 
                                                state={value}
                                                access={editVisitor}
                                            />
                                            <Delete 
                                                link={deleteVisitor ? `/delete/${slug}` : '#'} 
                                                state={value}
                                                access={deleteVisitor}
                                            />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Visited" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.nom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.prenom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.email || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.tel || "...." }
                                        </StyledTableCell>
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit 
                                                link={editVisited ? `/add/${slug}` : '#'} 
                                                state={value}
                                                access={editVisited}
                                            />
                                            <Delete 
                                                link={deleteVisited ? `/delete/${slug}` : '#'} 
                                                state={value}
                                                access={deleteVisited}
                                            />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            (slug === "Enterprise" || slug === "Category") &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        (menu[0]?.status && slug === "Enterprise") &&
                                        dataHeader.map((_, key) => 
                                            <StyledTableCell key={key} style={Styles.fontTable}>
                                                { value?.denomination ? value.denomination : '....' }
                                            </StyledTableCell>
                                        )
                                    }
                                    {
                                        (menu[0]?.status && slug === "Category") &&
                                        dataHeader.map((_, key) => 
                                            <StyledTableCell key={key} style={Styles.fontTable}>
                                                { value?.libelle ? value.libelle : '....' }
                                            </StyledTableCell>
                                        )
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            {
                                                slug === "Enterprise" &&
                                                <>
                                                    <Edit 
                                                        link={editEnterprise ? `/add/${slug}` : '#'} 
                                                        state={value}
                                                        access={editEnterprise}
                                                    />
                                                    <Delete 
                                                        link={deleteEnterprise ? `/delete/${slug}` : '#'} 
                                                        state={value}
                                                        access={deleteEnterprise}
                                                    />
                                                </>
                                            }
                                            {
                                                slug === "Category" &&
                                                <>
                                                    <Edit 
                                                        link={editCategory ? `/add/${slug}` : '#'} 
                                                        state={value}
                                                        access={editCategory}
                                                    />
                                                    <Delete 
                                                        link={deleteCategory ? `/delete/${slug}` : '#'} 
                                                        state={value}
                                                        access={deleteCategory}
                                                    />
                                                </>
                                            }
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Object" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { value?.nomobjet ? value.nomobjet : '....' }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1]?.status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { value.etatobjet ? "En cours d'utilisation" : "Pas encore utilisé"  }
                                        </StyledTableCell>
                                    }   
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit 
                                                link={editObject ? `/add/${slug}` : '#'} 
                                                state={value}
                                                access={editObject}
                                            />
                                            <Delete 
                                                link={deleteObject && !value.etatobjet ? `/delete/${slug}` : '#'} 
                                                state={value}
                                                access={deleteObject && !value.etatobjet}
                                            />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Badge" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                        {
                                            menu[0]?.status &&
                                            <StyledTableCell style={Styles.fontTable}>
                                                { value?.denomination || '....' }
                                            </StyledTableCell>
                                        }
                                        {
                                            menu[1]?.status &&
                                            <StyledTableCell style={Styles.fontTable}>
                                                { value?.etatbadge ? "Pas encore utilisé" : "En cours d'utilisation" }
                                            </StyledTableCell>
                                        }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit 
                                                link={editBadge ? `/add/${slug}` : '#'} 
                                                state={value}
                                                access={editBadge}
                                            />
                                            <Delete 
                                                link={deleteBadge && value.etatbadge ? `/delete/${slug}` : '#'} 
                                                state={value}
                                                access={deleteBadge && value.etatbadge}
                                            />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}