import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography, Button, Modal, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { Styles } from '../styles/style';
import { LoadingData, NullData } from './Loading';
import { StyledTableCell, StyledTableRow } from '../styles/style';
import profils from '../actions/profils';
import { useState } from 'react';

const initialState = [
    {
        name: "Dashboard",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Visiteurs",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Sociétés",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Catégories",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Visités",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Objets",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Badges",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    },
    {
        name: "Rapports",
        access: {
            view: false,
            edit: false,
            delete: false
        }
    }
]

const cellTableHead = (content, key)=> {
    return (
        <StyledTableCell key={key} align='left'>
            <Box sx={{
                color: Styles.color.darkGrey,
                textTransform: 'capitalize',
                padding: 1
            }} style={Styles.tableHeaderFont}>
                { content }
            </Box>
        </StyledTableCell>
    );
}

export default function ProfilsTable({ 
    openFullMenu, 
    dataHeader, 
    isOnload, 
    dataBody, 
    handleIsSubmitProfil 
}) {

    const [openDelete, setOpenDelete] = React.useState(false);
    const [profil, setProfil] = React.useState({});
    const [validation, setValidation] = useState("");
    const [open, setOpen] = useState(false);
    const [Access, setAccess] = useState([]);

    React.useEffect(()=> {
        setAccess(()=> JSON.parse(JSON.stringify(initialState.map(el => {
            if (profil?.accessControl?.includes("view" + el.name))
                el.access.view = true;
            else
                el.access.view = false;

            if (profil?.accessControl?.includes("edit" + el.name))
                el.access.edit = true;
            else
                el.access.edit = false;

            if (profil?.accessControl?.includes("delete" + el.name))
                el.access.delete = true;
            else
                el.access.delete = false;

            return el;
        }))));
    }, [profil]);

    const handleOpenDelete = (value)=> {
        setOpenDelete(true);
        setProfil({
            id: value.idprofil,
            denomination: value.denomination
        });
    }

    const handleCloseDelete = ()=> {
        setOpenDelete(false);
    }

    const handleSubmitDelete = async ()=> {
        try {
            await profils.deleteProfil(profil, profil.id);
            handleIsSubmitProfil();
        } catch (err) {
            console.error(err)
        }
        handleCloseDelete();
    }

    const handleOpen = (value)=> {
        setOpen(true);
        setProfil({
            id: value.idprofil,
            accessControl: value.tableAccess
        });
        setValidation(value.denomination);
    }
    const handleClose = ()=> {
        setOpen(false);
    }
    const handleSubmitProfil = async ()=> {
        try {
            const accessFilter = Access.map(el => {
                const controls = []
                if (el.access.view)
                    controls.push("view" + el.name);
                if (el.access.edit)
                    controls.push("edit" + el.name);
                if (el.access.delete)
                    controls.push("delete" + el.name);
                if (controls.length === 0)
                    return null;
                return controls;
            }).filter(el => el);
            await profils.editProfil({
                denomination: validation,
                tableAccess: accessFilter.flat()
            }, profil.id);
            handleIsSubmitProfil()
        } catch (err) {
            console.error(err);
        }
    }

    const handleChangeAccess = (e)=> {
        const id = Number.parseInt(e.target.value);
        const key = e.target.name;
        setAccess(prev => {
            return prev.map((item, index) => {
                if (index === id) {
                    return { ...item, access: { ...item.access, [key]: e.target.checked } };
                } else {
                    return item;
                }
            });
        });
    }

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 420 : window.innerWidth;

    return (
        <Box style={{
            maxWidth: `${maxWidth}px`
        }}>
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(content, key))}
                            <StyledTableCell align='right' style={Styles.tableHeaderFont}>
                                <Box sx={{
                                    marginRight: 3
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            dataBody.map((value, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.denomination || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.tableAccess.map(el=> el).join(' | ') || "...." }
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Box sx={{
                                            padding: 1,
                                            paddingBottom: 0.5,
                                            maxHeight: 25,
                                            backgroundColor: Styles.color.inputRed,
                                            borderRadius: 2,
                                            marginLeft: 1,
                                            cursor: 'pointer'
                                        }} title="editer"
                                            onClick={()=> handleOpen(value)}
                                        >
                                            <img width={20} src={'/assets/icons/edit.png'} alt="Edit" />
                                        </Box>
                                        <Box sx={{
                                                padding: 1,
                                                paddingBottom: 0.5,
                                                maxHeight: 25,
                                                backgroundColor: Styles.color.grey,
                                                borderRadius: 2,
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }} title="supprimer" 
                                            onClick={() => handleOpenDelete(value)}
                                        >
                                            <img width={20} src={'/assets/icons/delete.png'} alt="Delete" />
                                        </Box>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    width: {
                        xs: 200,
                        sm: 300,
                        md: 400,
                        lg: 600,
                        xl: 800
                    },
                    height: {
                        xs: "auto",
                        lg: 600
                    },
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                    borderRadius: 1.5,
                    padding: "1.5rem",
                    "&:focus-visible": {
                        outline: "none"
                    },
                    overflow: 'auto'
                }}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Ajout profile
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Dénomination
                                </Typography>
                                <TextField placeholder="Dénomination *"  sx={{ width: '100%' }}
                                    InputProps={{
                                        style: Styles.propsTextField
                                    }}
                                    onChange={e => setValidation(e.target.value)}
                                    value={validation}
                                    required
                                />
                                <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    {
                                        Access.map((el, index) => 
                                            <Grid item xs={12} sm={6} md={4} lg={2} key={el.name}>
                                                <Box sx={{
                                                    marginBottom: 1
                                                }}>
                                                    { el.name }
                                                </Box>
                                                <FormControlLabel control={
                                                        <Checkbox 
                                                            color="success"
                                                            name="view"
                                                            value={index}
                                                            onChange={handleChangeAccess}
                                                            checked={el.access.view}
                                                        />
                                                    }
                                                    label="Visibilité" sx={Styles.controlCheckBox}
                                                />
                                                <FormControlLabel control={
                                                        <Checkbox 
                                                            color="success"
                                                            name="edit"
                                                            value={index}
                                                            onChange={handleChangeAccess}
                                                            checked={el.access.edit}
                                                        />
                                                    }
                                                    label="Edité" sx={Styles.controlCheckBox}
                                                />
                                                <FormControlLabel control={
                                                        <Checkbox 
                                                            color="success"
                                                            name="delete"
                                                            value={index}
                                                            onChange={handleChangeAccess}
                                                            checked={el.access.delete}
                                                        />
                                                    }
                                                    label="Supprimé" sx={Styles.controlCheckBox}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: Styles.color.red
                                }
                            }} 
                        onClick={()=> {
                            if (!validation) return
                            handleSubmitProfil()
                            setValidation("");
                            handleClose();
                        }}>
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Suppression de profile
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Êtes-vous vraiment sûr de supprimer le profile ?
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained"
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseDelete}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                    "&:hover": {
                                        backgroundColor: Styles.color.red
                                }
                            }}
                            onClick={handleSubmitDelete}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}