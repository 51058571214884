import { 
    Box, 
    Grid, 
    Typography, 
    Button, 
    CircularProgress,
    OutlinedInput, 
    InputAdornment, 
    IconButton 
} from '@mui/material';
import { Styles } from '../styles/style';
import { useState } from 'react';
import { ArrowRightAlt, Visibility, VisibilityOff } from '@mui/icons-material';
import { store } from "../actions/store";
import users from '../actions/users';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { ShadowBox } from '../components/BoxShadow';

export const ChangePassword = () => {
    const signOut = useSignOut();
    const [error, setError] = useState(null);
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleHiddenPassword = ()=> {
        setPasswordHidden(prev => !prev);
    }
    
    const handleSubmit = async (e)=> {
        e.preventDefault();

        try {

            setLoading(true);
            
            if (password !== confirmPassword || password.length < 4) {
                throw new Error("Votre mot de passe ne correspond pas");
            }

            // Upload data to DB
            const { data: currUser } = store.tokenObject.authValue.userState;
            currUser.password = password;
            const res = await users.editUsers(currUser, currUser.id);
            if (res.status !== 200)
                throw new Error("Mot de passe non enregistré");

            signOut();
            window.location.reload();

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }

    }

    return (
        <ShadowBox>
            <Box sx={{
                position: "relative",
                background: `url(${'/assets/images/fond.png'})`,
                width: "100%",
                height: "75vh"
            }}>
                <Box sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: {
                        xs: 150,
                        sm: 200,
                        md: 250,
                        lg: 350,
                        xl: 400
                    },
                    height: 400,
                    margin: "auto",
                    boxShadow: "0px 4px 20px 0px #0000001A",
                    backgroundColor: "#fff",
                    borderRadius: 1.5,
                    padding: "1.5rem"
                }}>
                    <Grid 
                        container 
                        justifyContent="center"
                        sx={{
                            marginTop: 4
                        }}
                    >
                        <Grid item sx={{
                            fontSize: "1.2rem",
                            fontFamily: "Montserrat",
                            color: Styles.color.blue
                        }}>
                            Modification de mot de passe
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} sx={{
                            marginTop: error ? 0 : 1
                        }}>
                            <Grid item xs>
                                {
                                    error &&
                                    <Grid container sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "0.8rem",
                                        color: Styles.color.red,
                                        justifyContent: "center",
                                    }}>
                                        { error }
                                    </Grid>
                                }
                                <Typography style={ Styles.typography }>
                                    Mot de passe
                                </Typography>
                                <OutlinedInput
                                    placeholder="Votre nouveau mot de passe"
                                    type={passwordHidden ? "password" : "text"}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleHiddenPassword}
                                            edge="end"
                                        >
                                        {passwordHidden ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    sx={{ width: '100%' }}
                                    style={ Styles.propsTextField }
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                                <Typography style={ Styles.typography }>
                                    Confirmation mot de passe
                                </Typography>
                                <OutlinedInput
                                    placeholder="Confirmer votre nouveau mot de passe"
                                    type={passwordHidden ? "password" : "text"}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleHiddenPassword}
                                            edge="end"
                                        >
                                        {passwordHidden ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    sx={{ width: '100%' }}
                                    style={ Styles.propsTextField }
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Button sx={{
                            backgroundColor: Styles.color.blue,
                            border: `1px solid ${Styles.color.blue}`,
                            width: "100%",
                            marginTop: 4,
                            textTransform: "capitalize",
                            color: "white",
                            "&:hover": {
                                backgroundColor: Styles.color.blue,
                                color: "#ddd"
                            }
                        }} type='submit'>
                            {
                                loading ?
                                <CircularProgress color='secondary' size={20} sx={{
                                    marginRight: 1.5
                                }}/> :
                                <ArrowRightAlt sx={{
                                    marginRight: 1
                                }}/>
                            }
                            Valider
                        </Button>
                    </form>
                </Box>
            </Box>
        </ShadowBox>
    );
}