import API_URL from "./config";
import Construct from "./construct";

class Badges extends Construct {

    async createBadges(badge) {
        const data = await this.getBadges();
        const indexExist = data.findIndex(el => el.denomination ===  badge.denomination);
        if (indexExist > -1) return;
        try {
            const response = await this._request.post(`${API_URL}/badges`, {
                denomination: `${badge.denomination}`,
                etatbadge: badge.status,
                idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
            });
            return response;
        } catch (err) {
            throw new Error(err)
        }
    }

    async editBadge (badge, id) {
        try {
            const response = await this._request.put(`${API_URL}/badges/${id}`, {
                denomination: `${badge.denomination}`,
                etatbadge: badge.status,
                idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
            });
            return response;
        } catch (err) {
            throw new Error(err)
        }
    }

    async deleteBadge (badge, id) {
        try {
            const response = await this._request.put(`${API_URL}/badges/${id}`, {
                denomination: `${badge.denomination}`,
                etatbadge: badge.etatbadge,
                estActif: false,
                idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
            });
            return response;
        } catch (err) {
            throw new Error(err)
        }
    }

    async getBadges() {
        try {
            const response = await this._request.get(`${API_URL}/badges`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw new Error(err);
        }
    }

    async getBadgesAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/badges`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw new Error(err);
        }
    }

    async filterBadges(badge = "") {
        try {
            const response = await this._request.get(`${API_URL}/badges`);
            const { data } = response;
            const results = await this.isData(data);
            const input = badge.toLowerCase();
            const result = results.filter(el => {
                const denomination = el.denomination.toLowerCase()
                return (
                    denomination.indexOf(input) > -1 && el.etatbadge
                )
            });
            return result;
        } catch (err) {
            throw new Error(err)
        }
    }
}
const badges = new Badges();
export default badges;
