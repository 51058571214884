import API_URL from "./config";
import Construct from "./construct";

class Client extends Construct {

    async createJSONClient (client) {
        return {
            ...client,
            nomContact: "",
            couleurMenu: "",
            couleurBouton: "",
            couleurIcone: "",
            estActif: true
        }
    }

    async setClient (client) {
       try {

            const response = await this._request.post(`${API_URL}/client_admins`, await this.createJSONClient(client));
            if (response.status !== 201) 
                throw new Error();

            const { data } = response;
            const clientAdminId = data["@id"].split('/').at(-1);
            return clientAdminId;

       } catch (err) {
            throw err
       }
    }

}

const client = new Client();
export default client;