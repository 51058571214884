import { Button, ButtonBase } from "@mui/material"

export const ComponentButtonIcon = ({ 
    children, 
    backgroundColor,
    color, 
    onClick = null, 
    padding = 9, 
    border = "none",
    borderRadius = 4
})=> {
    return (
        <Button variant="contained" style={{
            minWidth: 40,
            fontSize: "21px",
            textAlign: "center",
            textTransform: 'capitalize',
            backgroundColor,
            border,
            borderRadius,
            padding,
            color
        }} onClick={onClick}>
            { children }
        </Button>
    )
}

export const ButtonEffect = ({ children })=> {
    return (
        <ButtonBase sx={{
            borderRadius: 1
        }}>
            { children }
        </ButtonBase>

    )
}