import { useState } from 'react';

const useShowColumn = ( HeaderTable, setHeaderTable ) => {
    
    const [anchorEl, setAnchorEl] = useState(null);

    const [menu, setMenu] = useState(() => 
        HeaderTable.map(el => {
            return {
                status: true,
                value: el
            }
        })
    );

    const open = Boolean(anchorEl);

    const handleCloseRow = () => {
      setAnchorEl(null);
    };

    const handleOpenRow = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleShowColumn = (index, checked)=> {
        setMenu(prev => {
                prev[index].status = checked;
                return prev;
            }
        );
        setHeaderTable(() => 
            menu.map(el => {
                if (el.status)
                    return el.value;
                return null;
            }).filter(el => el)
        )
    }

    return {
        open,
        anchorEl,
        menu,
        setMenu,
        handleOpenRow,
        handleCloseRow,
        handleShowColumn
    };
};

export default useShowColumn;