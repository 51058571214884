import uuid from "react-uuid";
import { Select, MenuItem } from "@mui/material";
import { useState } from "react";

export const InputSelect = ({ options, defaultOption = "", handleChangeOption = ()=> {} })=> {
    const [option, setOption] = useState(options.at(0));
    const [privateDefaultOption, setPrivateDefaultOption] = useState(defaultOption);

    const handleChange = (event) => {
        setPrivateDefaultOption("");
        setOption(event.target.value);
        handleChangeOption(event);
    }
   
    return (
        <>
            <Select
                style={{
                    padding: 0,
                    borderRadius: 4,
                    minWidth: "11rem",
                    height: "2.5rem",
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    fontWeight: 400,
                    letterSpacing: "0.5px",
                    textAlign: "left",
                    color: "#000"
                }}
                value={privateDefaultOption ? privateDefaultOption : option}
                onChange={handleChange}
            >
                 <MenuItem key={uuid()} 
                        value={privateDefaultOption}
                    >
                        {privateDefaultOption}
                </MenuItem>
                {options.map(option => 
                    <MenuItem key={uuid()} 
                        value={option}
                    >
                        {option}
                    </MenuItem>
                )}
            </Select>
        </>
    )
}
