import Construct from "./construct";
import API_URL from "./config";

class Profils extends Construct {
    async createJSON (profil) {
        return await {
            ...profil,
            denomination: profil?.denomination || "",
            estActif: true,
            users: [],
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }
    async setProfil (profil) {
        try {
            const response = await this._request.post(`${API_URL}/profils`, await this.createJSON(profil));
            if (response.status !== 201)
                throw new Error("Failed to set data");
            return true;
        } catch (err) {
            throw err;
        }
    }
    async editProfil (profil, id) {
        try {
            const response = await this._request.put(`${API_URL}/profils/${id}`, await this.createJSON(profil));
            if (response.status !== 200)
                throw new Error("Failed to set data");
            return true;
        } catch (err) {
            throw err;
        }
    }
    async getProfils () {
        try {
            const response = await this._request.get(`${API_URL}/profils`);
            if (response.status !== 200)
                throw new Error("Failed to fetch data");
            const { data } = response;
            const result = await this.isDataAdmin(data);
            const finalResult = result.filter(value => value.denomination !== "ROLE_ADMIN" && value.denomination !== "ROLE_USER")
            return finalResult;
        } catch (err) {
            throw err;
        }
    }
    async getAllProfils () {
        try {
            const response = await this._request.get(`${API_URL}/profils`);
            if (response.status !== 200)
                throw new Error("Failed to fetch data");
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }
    async deleteProfil (profil, id) {
        try {
            const profilTemp = await this.createJSON(profil)
            const response = await this._request.put(`${API_URL}/profils/${id}`, {
                ...profilTemp,
                estActif: false
            });
            if (response.status !== 200)
                throw new Error("Failed to update data");
            return true;
        } catch (err) {
            throw err;
        }
    }
}

const profils = new Profils();
export default profils;