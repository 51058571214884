import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HoursFormat, dateFormat } from '../actions/dateFormated';
import { 
    Box, 
    Button, 
    Grid, 
    Typography, 
    Modal, 
    Select, 
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { Styles } from '../styles/style';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { CustomizePagination } from './Pagination';
import uuid from 'react-uuid';
import object from '../actions/object';
import { useEffect, useState } from 'react';
import visite from '../actions/visite';
import objectRemise from '../actions/objectRemise';
import { Loading, LoadingData, NullData } from './Loading';
import { paginationFilter } from "../actions/pagination";
import { visitorSearch } from '../actions/visitorSearch';
import { StyledTableCell, StyledTableRow } from '../styles/style';
import { ShowModal } from './ShowModal';

const cellTableHead = (handleHeaderOrder, content, key)=> {
    return (
        <StyledTableCell key={key}>
            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: Styles.color.darkGrey,
                    padding: 1,
                    cursor: "pointer",
                    minWidth: 150
                }}
                onClick={()=>handleHeaderOrder(key)}
            >
                <Box sx={{
                    marginRight: 1,
                    textTransform: 'capitalize'
                }} style={Styles.tableHeaderFont}>
                    { content }
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <ExpandLess fontSize='15px' />
                    <ExpandMore fontSize='15px' />
                </Box>
            </Box>
        </StyledTableCell>
    );
}

export default function DashBoardTable({ 
    openFullMenu, 
    dataBody, 
    isOnload,
    setIsSubmited, 
    isOnSearch, 
    searchDate,
    handleEditBadgeActif,
    setNumber,
    dataHeader,
    menu,
    accessControl = []
}) {

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 340 : window.innerWidth;

    const editAccess = accessControl?.at(0)?.includes("editDashboard");
    
    const [updated, setUpdated] = useState(-1);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataObjectOption, setDataObjectOption] = useState([]);
    const [visited, setVisited] = useState({});
    const [pageNumbers, setPageNumbers] = useState(null);
    const [linesNumbers, setLinesNumbers] = useState(null);
    const [dataFilter, setDataFilter] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [checkPagination, setCheckPagination] = useState(10);
    
    const [openLeave, setOpenLeave] = useState(false);
    const [openObjectOption, setOpenObjectOption] = useState(false);
    const [openObjectCheked, setOpenObjectCheked] = useState(false);
    const [objectOption, setObjectOption] = useState([]);
    const [objectChecked, setObjectChecked] = useState([]);
    const [cannotGetOut, setCannotGetOut] = useState(false);
    const [orderBy, setOrderBy] = useState(()=>{
        return {
            asc: 1,
            count: -1
        }
    });

    const callSetObjectChecked = async (data)=> {
        const allObjectRemise = await objectRemise.getObjectRemise();
        let dataTemp = []
        if (data?.object.length === 0) {
            setIsLoading(false);
            return;
        }
        data.object.forEach(object => {
            const jsonObjectRemise = {
                visite: data?.idvisite, 
                objectType: object.idtypeobjet
            }                
            const waitingFilter = async () => {
                const dataFilter = await objectRemise.filterObjectRemise(allObjectRemise, jsonObjectRemise);
                dataTemp.push(dataFilter);
            }
            const promisingFilter = async ()=> {
                await waitingFilter();
                setObjectChecked(dataTemp);
                setIsLoading(false);
            }
            promisingFilter();
        })
        return dataTemp;
    }

    const handlePagesNumbers = ()=> {
        const pages = (isOnSearch || searchDate)
        ? 
            Math.floor((dataFilter.length - 1) / checkPagination)
        :
            Math.floor((dataBody.length - 1) / checkPagination);
            
        setPageNumbers(pages);
    }

    const handlePaginationActive = (activePage)=> {
        setActivePage(activePage)
    }
    
    const handlePaginationChecked = (checkPagination)=> {
        setCheckPagination(checkPagination)
    }
    
    const handleOpenLeave = async (data)=> {
        const object = await callSetObjectChecked(data);
        setCannotGetOut(() => object?.length ? true : false);
        setOpenLeave(true);
        setVisited(data);
    }
    
    const handleCloseLeave = ()=> {
        setOpenLeave(false);
        setVisited({});
    }

    const handleOpenObjectOption = (data)=> {
        setOpenObjectOption(true);
        setVisited(data);
    }

    const handleCloseObjectOption = ()=> {
        setOpenObjectOption(false);
        setVisited({});
    }

    const handleOpenObjectCheked = async (data)=> {
        setOpenObjectCheked(true);
        setIsLoading(true);
        await callSetObjectChecked(data);
        setVisited(data);
    }

    const handleCloseObjectChecked = ()=> {
        setOpenObjectCheked(false);
        setObjectChecked([]);
        setVisited({});
    }

    const handleObjectOption = (event)=> {
        const {
            target: { value },
        } = event;
        setObjectOption(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleObjectChecked = (event, object)=> {
        const {
            target: { checked },
        } = event;
        object.isCheked = checked;
    }

    const handleSubmitLeave = async ()=> {
        setIsUpdated(true);
        try {
            const currObject = visited?.object.map(object => 
                `/api/typeobjets/${object.idtypeobjet}`
            );
            const visitedFormated = {
                createdAt: visited?.dateEntre,
                numvisiteur: visited?.numvisiteur || null,
                visiteur: visited?.visiteur?.idvisiteur || null,
                personnelVisite: visited?.personnelVisite?.idpersonnelvisite || null,
                object: [...currObject]
            }
            await visite.editVisite(visitedFormated, visited.idvisite, true);
            await handleEditBadgeActif(visited.numvisiteur);
            setUpdated(prev => prev * -1);
        } catch (err) {
            console.error(err);
        }
        handleCloseLeave();
    }

    const handleSubmitObjectOption = async ()=> {
        setIsUpdated(true);
        try {
            const currObject = visited?.object.map(object => 
                `/api/typeobjets/${object.idtypeobjet}`
            );
            const objectFormated = objectOption.map(objectId => 
                `/api/typeobjets/${objectId}`
            );
            const visitedFormated = {
                createdAt: visited?.dateEntre,
                numvisiteur: visited?.numvisiteur || null,
                visiteur: visited?.visiteur?.idvisiteur || null,
                personnelVisite: visited?.personnelVisite?.idpersonnelvisite || null,
                object: [...currObject, ...objectFormated]
            }
            const visitedId = await visite.editVisite(visitedFormated, visited.idvisite);
            objectOption.forEach(objectId => {
                const jsonObjectRemise = {
                    visite: visitedId,
                    objectType: objectId
                }
                const callAddObjectRemise = async ()=> {
                    await objectRemise.setObjectRemise(jsonObjectRemise);
                    setUpdated(prev => prev * -1);
                }
                callAddObjectRemise();
            })
        } catch (err) {
            console.error(err);
        }
        handleCloseObjectOption();
        setObjectOption([]);
    }

    const handleSubmitObjectChecked = async ()=> {
        setIsUpdated(true);
        try {
            const objectFiltered = objectChecked.filter(object => 
                object.at(0)?.isCheked !== true
            );
            const objectFormated = objectFiltered.map(object => 
                `/api/typeobjets/${object.at(0)?.typeObjet.idtypeobjet}`
            );
            const visitedFormated = {
                createdAt: visited?.dateEntre,
                numvisiteur: visited?.numvisiteur || null,
                visiteur: visited?.visiteur?.idvisiteur || null,
                personnelVisite: visited?.personnelVisite?.idpersonnelvisite || null,
                object: [...objectFormated]
            }
            const visitedId = await visite.editVisite(visitedFormated, visited.idvisite);
            const objectUpdated = objectChecked.filter(object => 
                object.at(0)?.isCheked === true
            );
            objectUpdated.forEach(object => {
                const jsonObjectRemise = {
                    visite: visitedId,
                    objectType: `${object.at(0)?.typeObjet.idtypeobjet}`
                }
                const callEditObjectRemise = async ()=> {
                    await objectRemise.editObjectRemise(jsonObjectRemise, object.at(0).idremiseobjet);
                    setUpdated(prev => prev * -1);
                }
                callEditObjectRemise();
            })
        } catch (err) {
            console.error(err);
        }
        handleCloseObjectChecked();
        setObjectChecked([]);
    }

    const handleHeaderOrder = (index)=> {

        const nextState = {
            ...orderBy,
            count: index,
            asc: orderBy.count === index 
                ? orderBy.asc * -1
                : orderBy.asc
        }

        setOrderBy(nextState);

        const dataOrdered = [...dataFilter];

        switch(index) {
            case 0:
                dataOrdered.sort((a, b) => {
                    if (a?.numvisiteur < b?.numvisiteur) {
                        return - nextState.asc;
                    }
                    if (a?.numvisiteur > b?.numvisiteur) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 1: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.nom < b?.visiteur?.nom) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.nom > b?.visiteur?.nom) {
                        return  nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 2: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.cin < b?.visiteur?.cin) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.cin > b?.visiteur?.cin) {
                        return  nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 3: 
                dataOrdered.sort((a, b) => {
                    if (a?.visiteur?.societe?.denomination < b?.visiteur?.societe?.denomination) {
                        return - nextState.asc;
                    }
                    if (a?.visiteur?.societe?.denomination > b?.visiteur?.societe?.denomination) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 4: 
                dataOrdered.sort((a, b) => {
                    if (a?.personnelVisite?.nom < b?.personnelVisite?.nom) {
                        return - nextState.asc;
                    }
                    if (a?.personnelVisite?.nom > b?.personnelVisite?.nom) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 5: 
                dataOrdered.sort((a, b) => {
                    if (a?.dateEntre < b?.dateEntre) {
                        return - nextState.asc;
                    }
                    if (a?.dateEntre > b?.dateEntre) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 6: 
                dataOrdered.sort((a, b) => {
                    if ((a?.dateSortie || "") < (b?.dateSortie || "")) {
                        return - nextState.asc;
                    }
                    if ((a?.dateSortie || "") > (b?.dateSortie || "")) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            case 7: 
                dataOrdered.sort((a, b) => {
                    if (a?.object?.length < b?.object?.length) {
                        return - nextState.asc;
                    }
                    if (a?.object?.length > b?.object?.length) {
                        return nextState.asc;
                    }
                    return 0;
                });
                return setDataFilter(dataOrdered);
            default:
                return setDataFilter(dataFilter);
        }
    }

    useEffect(()=> {
        if (isOnSearch || searchDate)
            setNumber(dataFilter.filter(el => !el?.dateSortie).length);
        if (!isOnSearch && !searchDate)
            setNumber(dataBody.filter(el => !el?.dateSortie).length);
    }, [dataBody, dataFilter, setNumber, isOnSearch, searchDate]);

    useEffect(()=> {
        const results = paginationFilter(dataBody, activePage, checkPagination);
        if (isOnSearch || searchDate) {
            const dataFiltered = visitorSearch(dataBody, isOnSearch, searchDate);
            setDataFilter(dataFiltered);
            setLinesNumbers(dataFiltered.length);
        }
        else {
            setDataFilter(results);
            setLinesNumbers(results.length);
        }
    }, [dataBody, activePage, checkPagination, isOnSearch, searchDate])

    useEffect(()=> {
        const callGetObject = async()=> {
            const data = await object.getObject();
            setDataObjectOption(data);
        }
        callGetObject();
    }, [])

    useEffect(()=> {
        setIsSubmited(prev => prev * -1);
    }, [updated, setIsSubmited]);

    return (
        <Box style={{
            maxWidth: maxWidth
        }}>
            <TableContainer 
                component={Paper} 
                sx={{
                    borderRadius: 2
                }}
            >
                <Table minWidth={700}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(handleHeaderOrder, content, key))}
                            <StyledTableCell 
                                align='right' 
                                style={Styles.tableHeaderFont} 
                            >
                                <Box sx={{
                                    padding: 1.5,
                                    paddingRight: 9
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (isOnload && !isUpdated) ?
                                <LoadingData length={dataHeader.length} />
                            :
                            dataFilter.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            dataFilter.map(data => (
                                <StyledTableRow key={uuid()}>
                                    {
                                        menu[0].status &&
                                        <StyledTableCell component="th" scope="row" style={Styles.fontTable}>
                                            { data?.numvisiteur || "...."}
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { 
                                                (data?.visiteur?.nom || "....") + " " + (data?.visiteur?.prenom || "")
                                            }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { 
                                                data?.visiteur?.cin || "...."
                                            }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { data?.visiteur?.societe?.denomination || "...."}
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[4].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            {
                                                (data?.personnelVisite?.nom || "....") + " " + (data?.personnelVisite?.prenom || "") 
                                            }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[5].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { 
                                                (dateFormat(data?.dateEntre) || "....") + " " + 
                                                (HoursFormat(data?.dateEntre) || "")
                                            }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[6].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            {
                                                (dateFormat(data?.dateSortie) || "....") + " " + 
                                                (HoursFormat(data?.dateSortie) || "")
                                            }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[7].status &&
                                        <StyledTableCell style={Styles.fontTable}>
                                            { data?.object?.length || "Aucun" }
                                        </StyledTableCell>
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Box sx={{
                                                    padding: 0.8,
                                                    paddingBottom: 0,
                                                    maxHeight: 28,
                                                    border: `2px solid ${Styles.color.inputRed}`,
                                                    borderRadius: 1,
                                                    marginLeft: 1,
                                                    opacity: editAccess ? 1 : 0.5,
                                                    cursor: editAccess ? 'pointer' : 'not-allowed'
                                                }} 
                                                onClick={() => { 
                                                    if (!editAccess) return;
                                                    handleOpenLeave(data)
                                                }}
                                                title="sortir"
                                            >
                                                <img width={20} src={'/assets/icons/Vector.png'} alt="Vector" />
                                            </Box>
                                            <Box sx={{
                                                    marginLeft: 1,
                                                    opacity: editAccess ? 1 : 0.5,
                                                    cursor: editAccess ? 'pointer' : 'not-allowed'
                                                }} 
                                                onClick={() => {
                                                    if (!editAccess) return;
                                                    handleOpenObjectOption(data) 
                                                }}
                                                title="affecter objet"
                                            >
                                                <img width={40} src={'/assets/icons/greyHand.png'} alt="Group 90" />
                                            </Box>
                                            <Box sx={{
                                                    marginLeft: 1,
                                                    opacity: editAccess ? 1 : 0.5,
                                                    cursor: editAccess ? 'pointer' : 'not-allowed'
                                                }} 
                                                onClick={() => {
                                                    if (!editAccess) return;
                                                    handleOpenObjectCheked(data) 
                                                }}
                                                title="retour object"    
                                            >
                                                <img width={40} src={'/assets/icons/greenHand.png'} alt="Group 91" />
                                            </Box>
                                            <ShowModal data={data} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{
                marginTop: 5
            }}>
                <CustomizePagination 
                    linesNumbers={linesNumbers}
                    pageNumbers={pageNumbers}
                    handlePagesNumbers={handlePagesNumbers}
                    handlePaginationChecked={handlePaginationChecked}
                    handlePaginationActive={handlePaginationActive}
                />
            </Box>
            <Modal
                open={openLeave}
                onClose={handleCloseLeave}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Sortir de l'entreprise
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    {
                                        cannotGetOut ?
                                        "Cette personne doit retourner des objets"
                                        : 
                                        "Confirmer la sortie" 
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained"
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseLeave}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: Styles.color.red
                                },
                                "&:disabled": {
                                    backgroundColor: Styles.color.red,
                                    color: "white",
                                    opacity: 0.5
                                }
                            }}
                            onClick={handleSubmitLeave}
                            disabled={cannotGetOut}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openObjectOption}
                onClose={handleCloseObjectOption}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Remise d'objet
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Objet
                                </Typography>
                                <Select
                                    sx={{
                                        width: "100%",
                                        height: "2.5rem"
                                    }}
                                    style={Styles.inputDefaultTypo}
                                    multiple
                                    value={objectOption}
                                    onChange={handleObjectOption}
                                >
                                    {
                                        dataObjectOption.map(object => 
                                            <MenuItem key={uuid()} value={`${object.idtypeobjet}`}>{ object.nomobjet }</MenuItem>
                                        )   
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained"
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseObjectOption}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                    "&:hover": {
                                        backgroundColor: Styles.color.red
                                }
                            }}
                            onClick={handleSubmitObjectOption}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openObjectCheked}
                onClose={handleCloseObjectChecked}
            >
                <Box sx={Styles.modals} style={{
                    height: 240
                }}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Retour d'objet
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="column" sx={{
                                height: 130,
                                overflow: "auto"
                            }}> 
                                {   isLoading ?
                                        <Loading />
                                    :
                                    objectChecked.length === 0 ?
                                    <Typography style={Styles.inputDefaultTypo}>
                                        Aucun objet
                                    </Typography>
                                    : objectChecked.map(objects => {
                                            return objects.map(object => 
                                                    <FormControlLabel key={uuid()} control={
                                                        <Checkbox 
                                                            color="success"
                                                            onChange={event => { 
                                                                handleObjectChecked(event, object)
                                                            }}
                                                        />
                                                    }
                                                    label={`${object?.typeObjet?.nomobjet} (${object?.nombreobjet})`} sx={Styles.inputDefaultTypo}
                                                />
                                            )
                                        }
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseObjectChecked}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: Styles.color.red
                                }
                            }}
                            onClick={handleSubmitObjectChecked}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}