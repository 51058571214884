import API_URL from "./config";
import Construct from "./construct";

class Visite extends Construct {

    async _createJSONvisite(visite) {
        const newDate = new Date()
        return await {
            ...visite,
            dateEntre: visite?.createdAt || newDate.toISOString(),
            dateSortie: null,
            commentaires: "",
            etatvisite: true,
            visiteur: `/api/visiteurs/${visite.visiteur}`,
            personnelVisite: `/api/personnelvisites/${visite.personnelVisite}`,
            user: `/api/users/${await this.getUserId()}`,
            estActif: true,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setVisite(visite) {
        try {
            const response = await this._request.post(`${API_URL}/visites`, await this._createJSONvisite(visite))
            
            if (response.status !== 201) 
                throw new Error();
            
            const { data } = response;
            const visitedId = data["@id"].split('/').at(-1);

            return visitedId;
        } catch (err) {
            throw err;
        }
    }

    async editVisite(visite, id, getOut = null) {
        try {
            if (getOut) {
                const newDate = new Date()
                const jsonUpdate = await this._createJSONvisite(visite)
                const response = await this._request.put(`${API_URL}/visites/${id}`, {
                    ...jsonUpdate,
                    dateSortie:newDate.toISOString()
                })
                return response;
            }
            const response = await this._request.put(`${API_URL}/visites/${id}`, await this._createJSONvisite(visite))
            if (response.status !== 200) 
                throw new Error();
        
            const { data } = response;
            const visitedId = data["@id"].split('/').at(-1);

            return visitedId;
        } catch (err) {
            throw err;
        }
    }

    async getVisite() {
        try {
            const response = await this._request.get(`${API_URL}/visites`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }  
}
const visite = new Visite();
export default visite;
