import API_URL from "./config";
import Construct from "./construct";

class VisitedPersonne extends Construct {

    async _createJSONvisitedPersonne(visitedPersonne) {
        return await {
            ...visitedPersonne,
            postefonction: "",
            service: "",
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setVisitedPersonne(visitedPersonne) {
        try {
            const response = await this._request.post(`${API_URL}/personnelvisites`, await this._createJSONvisitedPersonne(visitedPersonne))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async editVisitedPersonne(visitedPersonne, id) {
        try {
            const response = await this._request.put(`${API_URL}/personnelvisites/${id}`, await this._createJSONvisitedPersonne(visitedPersonne))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getVisitedPersonne() {
        try {
            const response = await this._request.get(`${API_URL}/personnelvisites`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async getVisitedPersonneAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/personnelvisites`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async deleteVisitedPersonne(visitedPersonne, id) {
        try {
            const _visitedPersonne = await this._createJSONvisitedPersonne(visitedPersonne);
            const response = await this._request.put(`${API_URL}/personnelvisites/${id}`, {
                ..._visitedPersonne,
                prenom: "",
                email: "",
                tel: "",
                estActif: false
            })
            return response;
        } catch (err) {
            throw err;
        }
    }
    
}
const visitedPersonne = new VisitedPersonne();
export default visitedPersonne;
