import Menu from '@mui/material/Menu';
import { ButtonEffect } from './ButtonIcon';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Styles } from '../styles/style';

export const ShowColumn = ( { 
    open,
    anchorEl,
    handleOpenRow,
    handleCloseRow,
    handleShowColumn,
    menu
 } )=> {
    return (
        <>
            <ButtonEffect>
                <Box sx={{
                        border: "1px solid #869CB0",
                        borderRadius: 2,
                        padding: "11px 10px 7px 9px"
                    }}
                    onClick={handleOpenRow}
                >
                    <img src={'/assets/icons/docs.png'} alt="docs" />
                </Box>
            </ButtonEffect>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseRow}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {
                    menu.map((list, index) => 
                        <FormGroup 
                            key={index}
                            sx={{
                                padding: "0 15px"
                            }}
                        >
                            <FormControlLabel 
                                control={<Checkbox checked={list.status} />} 
                                label={ 
                                    <Typography sx={Styles.fontTable}>{list.value}</Typography>
                                }
                                onClick={e =>handleShowColumn(index, e.target.checked)}
                            />
                        </FormGroup>
                    )
                }
            </Menu>
        </>
    );
}
