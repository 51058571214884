import API_URL from "./config";
import Construct from "./construct";

class Enterprise extends Construct {

    async _createJSONEnterprise(enterprise) {
        return {
            denomination: enterprise,
            commentaires: null,
            email: null,
            tel: null,
            nomcontact: null,
            estActif: true,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setEnterprise(enterprise) {
        try {
            const response = await this._request.post(`${API_URL}/societes`, await this._createJSONEnterprise(enterprise))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async editEnterprise(enterprise, id) {
        try {
            const response = await this._request.put(`${API_URL}/societes/${id}`, await this._createJSONEnterprise(enterprise));
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getEnterprise() {
        try {
            const response = await this._request.get(`${API_URL}/societes`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async getEnterpriseAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/societes`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async deleteEnterprise(enterprise, id) {
        try {
            const _enterprise = await this._createJSONEnterprise(enterprise.denomination);
            const response = await this._request.put(`${API_URL}/societes/${id}`, {
                ..._enterprise,
                estActif: false
            });
            return response;
        } catch (err) {
            throw err;
        }
    }

}
const enterprise = new Enterprise();
export default enterprise;
