import { useEffect, useMemo, useState } from "react";
import { ShadowBox } from "../components/BoxShadow";
import { Grid, Typography, TextField, Box, Select, MenuItem } from "@mui/material";
import { Styles } from "../styles/style";
import { ComponentButton } from "../components/Button";
import users from "../actions/users";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { store } from "../actions/store";
import profils from "../actions/profils";

export const SetClient = ()=> {
    const location = useLocation();
    const { from } = location?.state || {};
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(from ? from?.roles.at(-1) : roles.at(-1)?.denomination || "ROLE_USER");
    const clientAdminId = useMemo(()=> {
        return store.tokenObject.authValue?.userState?.data?.idclientAdmin
    }, []);
    const navigate = useNavigate();
    const [user, setUser] = useState(from ? 
        {
            email: from.email,
            password: "",
            nomagent: from.nomagent || "",
            prenomagent: from.prenomagent || "",
            matriculeagent: from.matriculeagent || "",
            telagent: from.telagent || "",
            idclientAdmin: from.idclientAdmin || null,
            roles: from.roles
        }
        :
        {
            email: "",
            password: "",
            nomagent: "",
            prenomagent: "",
            matriculeagent: "",
            telagent: "",
            idclientAdmin: clientAdminId || null,
            roles: ["ROLE_USER"]
        }
    )
    const handleRoleChange = (event)=> {
        setRole(event.target.value)
    }
    const handleUserChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
            roles: role !== "ROLE_USER" ? ["ROLE_USER", role] : [role]
        })
    }
    const handleSubmit = async (e)=> {
        e.preventDefault();
        try {
            if (from) {
                const response = await users.editUsers(user, from.id);
                if (response.status === 200)
                    navigate('/setting/users');
            }
            else {
                const response = await users.setUsers(user);
                if (response.status === 200)
                    navigate('/setting/users');
            }
        } catch (err) {
            console.error("Un erreur est survenu ou cette email exist déjà");   
        }
    }
    useEffect(()=> {
        const asyncGetProfils = async ()=> {
            try {
                const data = await profils.getAllProfils();
                setRoles(data);
            } catch (err) {
                console.error(err);
            }
        }
        asyncGetProfils()
    }, [])
    return (
        <ShadowBox>
            <Box sx={{
                marginY: 2
            }}>
                <Link to="/setting/users" style={{
                    color: Styles.color.dark
                }}>
                    <ArrowBack />
                </Link>
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{
                    marginBottom: 3
                }}>
                    <Grid item xs={6}>
                        <Grid container direction="row">
                            <Typography style={ Styles.typography }>
                                Nom *
                            </Typography>
                            <TextField placeholder="Nom"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="nomagent"
                                value={user.nomagent}
                                onChange={handleUserChange}
                                required
                            />
                            <Typography style={ Styles.typography }>
                                Prénom
                            </Typography>
                            <TextField placeholder="Prénom"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="prenomagent"
                                value={user.prenomagent}
                                onChange={handleUserChange}
                            />
                            <Typography style={ Styles.typography }>
                                Matricule
                            </Typography>
                            <TextField placeholder="Matricule agent"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="matriculeagent"
                                value={user.matriculeagent}
                                onChange={handleUserChange}
                            />
                            <Typography style={ Styles.typography }>
                                Rôle
                            </Typography>
                            <Select
                                sx={{
                                    width: "100%",
                                    height: "2.5rem"
                                }}
                                style={Styles.inputDefaultTypo}
                                value={role}
                                onChange={handleRoleChange}
                                required
                            >
                                {
                                    roles.map(value =>
                                        <MenuItem 
                                            key={value.idprofil}
                                            value={value.denomination}
                                        >
                                            {
                                                value.denomination !==  "ROLE_USER" && value.denomination !==  "ROLE_ADMIN" ?
                                                value.denomination :
                                                value.denomination === "ROLE_USER" ? "AGENT" :
                                                value.denomination === "ROLE_ADMIN" && "ADMIN"
                                            }
                                        </MenuItem>  
                                    )
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction="row">
                            <Typography style={ Styles.typography }>
                                Téléphone
                            </Typography>
                            <TextField placeholder="Tel agent"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="telagent"
                                value={user.telagent}
                                onChange={handleUserChange}
                            />
                            <Typography style={ Styles.typography }>
                                Email *
                            </Typography>
                            <TextField placeholder="Email"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="email"
                                type="email"
                                value={user.email}
                                onChange={handleUserChange}
                                required
                            />
                            <Typography style={ Styles.typography }>
                                Mot de passe *
                            </Typography>
                            <TextField placeholder="Mot de passe"  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                type="password"
                                name="password"
                                value={user.password}
                                onChange={handleUserChange}
                                required
                            />
                            <Grid 
                                container 
                                spacing={0} 
                                justifyContent="flex-end"
                                sx={{
                                    marginTop: 6
                                }}
                            >
                                <Grid item>
                                    <ComponentButton backgroundColor={ Styles.color.red }>Valider</ComponentButton>
                                </Grid>
                            </Grid>  
                        </Grid>
                    </Grid>
                </Grid> 
            </form>
        </ShadowBox>
    )
}