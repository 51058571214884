import React, { createContext, useContext, useState } from 'react';
import { useVisitorForm } from './customVisitorHooks';
import { dateFormat } from './dateFormated';

const VisitorContext = createContext();

export const VisitorProvider = ({ children }) => {
    
    const { visitorJSON, visitorInput, setVisitorInput } = useVisitorForm();
    const [isSelect, setIsSelect] = useState(true);

    const handleVisitorChange = (e)=> {
        setVisitorInput({
            ...visitorInput,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeCINDate = (date)=> {
        setVisitorInput({
            ...visitorInput,
            cinDateEcheance: date.$d.toISOString()
        })
    }

    
    const handleChangeDateFormation = (date)=> {
        setVisitorInput({
            ...visitorInput,
            dateFormation: date.$d.toISOString()
        })
    }

    const handleChangeEcheanceFormation = (date)=> {
        setVisitorInput({
            ...visitorInput,
            dateFormationEcheance: date.$d.toISOString()
        })
    }

    const handleSelectChange = (enterpriseOption = null, categoryOption = null) => {
        visitorInput.societe = enterpriseOption;
        visitorInput.categorieVisiteur = categoryOption;
    }

    const handleIsVisitorOnSearch = (visitor)=> {
        setIsSelect(false);
        setVisitorInput({
            ...visitorInput,
            idvisiteur: visitor.idvisiteur,
            nom: visitor.nom,
            prenom: visitor.prenom,
            cin: visitor.cin,
            cinDateEcheance: dateFormat(visitor.cinDateEcheance),
            dateFormation: dateFormat(visitor.dateFormation),
            dateFormationEcheance: dateFormat(visitor.dateFormationEcheance),
            categorieVisiteur: visitor.categorieVisiteur.idcategorievisiteur,
            societe: visitor.societe.idsociete
        })
    }

    const handleVisitorReset = ()=> {
        setIsSelect(true);
        setVisitorInput(visitorJSON);
    }

    return (
        <VisitorContext.Provider value={{ 
                isSelect,
                visitorInput, 
                setVisitorInput,
                handleVisitorChange,
                handleSelectChange,
                handleChangeCINDate,
                handleChangeDateFormation,
                handleChangeEcheanceFormation,
                handleIsVisitorOnSearch,
                handleVisitorReset
            }}
        >
            {children}
        </VisitorContext.Provider>
    );
};

export const useVisitorContext = () => useContext(VisitorContext);
