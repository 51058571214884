export const dateFormat = (date) => {
    if (!date) return;
    const currDate = new Date(date);
    const newDate = String(currDate.getDate()).padStart(2, '0') + "/" + String(currDate.getMonth()+1).padStart(2, '0') + "/" + currDate.getFullYear();
    return newDate;
}

export const HoursFormat = (date) => {
    if (!date) return;
    const currDate = new Date(date);
    const newHours= String(currDate.getHours()).padStart(2, '0') + ":" + String(currDate.getMinutes()).padStart(2, '0') + 
    ":" + String(currDate.getSeconds()).padStart(2, '0');
    return newHours;
}

export const enFormatDate = (currDate) => {
    return new Date(currDate.split('/').reverse().join('/'))
}